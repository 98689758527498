import axios from 'axios'
import InstanceStatus from '../../molecules/instancestatus/InstanceStatus'
import {
  useAuth
} from '../../../lib/useAuth'
import NodeModal from '../nodemodals/NodeModals'
import env from '../../../lib/env'

const NodeList = (props) => {
  const nodes = props.nodes
  const host = props.host
  const volumes = host.volumes
  const environment = props.environment
  const auth = useAuth()

  const computeCost = (status, node, volumes) => {
    const nodeCost = node.cost
    let volumeCost = 0.00
    volumes.forEach(volume => {volumeCost += parseFloat(volume.cost)})
    let number = parseFloat(volumeCost)
    if (status === "running") {
      number += parseFloat(nodeCost)
    }
    return number.toFixed(2)
  }

  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 200000,
    headers: auth.getAuthorizationHeaders()
  })

  const getNodeVolumes = (volumes, node_id) => {
    let node_volumes = []
    volumes.forEach(volume => {
      if (volume.node_id === node_id) {
        node_volumes.push(volume)
      }
    })
    return node_volumes
  }

  return (
    <>
      {nodes.length > 0 && 
        <>
          { nodes.map((node, i) => {
            let node_volumes = getNodeVolumes(volumes, node.id)
            let cost = computeCost(node.status, node, node_volumes)
            return (
              <tr key={i}>
                <td>{node.node_name}</td>
                <td>EKS Node</td>
                <td>{node.identifier}</td>
                <td>~${cost}</td>
                <InstanceStatus instance={node} auth={auth} axiosClient={axiosClient} type={host.host_type}/>
                <td>{node.ip_address}</td>
                <td>{environment.owner_tag}</td>
                <td>{environment.cost_center_tag}</td>
                <td>
                  <NodeModal volumes={node_volumes} node={node} host={host}/>
                </td>
              </tr>
            )
          })}
        </>
      }
    </>
  )
}

export default NodeList