import React from "react";
import { Table } from "reactstrap";

class ImageInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      amis: props.amis,
      comments: props.comments
    }
  }

  render () {
    return (
      <>
        <p><b>Images:</b></p>
        <Table size="sm" bordered>
          <thead>
            <tr>
              <th style={{color:'white'}}>ID</th>
              <th style={{color:'white'}}>Image</th>
              <th style={{color:'white'}}>Resource Created At</th>
              <th style={{color:'white'}}>Automated Backup?</th>
              <th style={{color:'white'}}>Comments</th>
            </tr>
          </thead>
        {
          this.state.amis.map((image, i) => {
            let data = <tr>
              <td style={{color:'white'}}>{image.id}</td>
              <td style={{color:'white'}}>{image.identifier}</td>
              <td style={{color:'white'}}>{image.resource_created}</td>
              <td style={{color:'white'}}>{image.auto_backup === true ? "Yes" : "No"}</td>
              <td style={{color:'white'}}>{image.identifier in this.state.comments ? this.state.comments[image.identifier] : ''}</td>
            </tr>
            return data
          })
        }
        </Table>
      </>
    )
  }
}

export default ImageInfo