import React from 'react';
import { 
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Form, 
  FormGroup, 
  Label, 
  Input
} from 'reactstrap';


class IssueModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false, 
      submit: "Report Issue",
      name: "", 
      email: "",
      description: ""
    };
    this.auth = this.props.auth;
    this.axiosClient = this.props.axiosClient;
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleTextFieldChange = this.handleTextFieldChange.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleTextFieldChange(e) {
    let key = e.target.id
    let value = e.target.value
    this.setState({ [key]: value });
  }

  handleSubmit(e) {
    e.preventDefault(true);
    this.setState({submit: "Submitting..."})
    let baseUrl = "https://api.admin-portal.igt.com";
    let auth_token = 'Token token=dd60503fd5a2eac3d38e86237a402ee7';
    if (window.location.hostname === 'localhost') {
      baseUrl = "http://localhost:3000";
      auth_token = 'Token token=ca755ea6a6901ab5836eab860992f0e1';
    }
    let params = {
      name: this.state.name, 
      email: this.state.email, 
      description: this.state.description
    }
    this.axiosClient.post(`${baseUrl}/salesforce/vlt_issue`, params, {
      headers: {
        'Authorization': auth_token
      }
    }).then(resp => {
        alert('Your request has been submitted to the CCoE team for approval!');
        console.log("API Response: ", resp);
        this.setState({
          modal: false,
          submit: "Report Issue"
        });
      }).catch(err => {
        alert('Something went wrong! This is usually due to you being connected to the corporate VPN. Please disconnect from VPN and try your request again.');
        console.error("API Error: ", err);
        this.setState({ submit: "Report Issue" });
      });
  }

  

  render() {
    const bttnStyle = {
      borderRadius: "24px",
      border: "2px",
      padding: "5px 20px",
      textAlign: "center",
      display: "inline-block",
      margin: "4px 9px",
    }
    
    const txtColor ={
      color: 'white',
    }

    const modalColor = {
      backgroundColor:'rgba(27, 32, 99, 1)',
      color:'white'
    }
    
    return (
      <div >
        <Button color="primary" block onClick={this.toggle} style={bttnStyle} >Report an Issue</Button>
        <Modal  isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader toggle={this.toggle} style={modalColor}>Submit an Issue/Feature Request</ModalHeader>
            <Form onSubmit={this.handleSubmit} style={modalColor}>
              <ModalBody>
              <FormGroup>
                <Label for="name" style={txtColor}>Your Full Name:</Label>
                <Input style={{backgroundColor: 'transparent', color: 'white'}} type="text" name="name" id="name" placeholder='First Last' onChange={this.handleTextFieldChange}></Input>
              </FormGroup>
              <FormGroup>
                <Label for="email" style={txtColor}>Your Email:</Label>
                <Input style={{backgroundColor: 'transparent', color: 'white'}} type="text" name="email" id="email" placeholder='first.last@igt.com' onChange={this.handleTextFieldChange}></Input>
              </FormGroup>
              <FormGroup>
                <Label for="email" style={txtColor}>Description of Error/Feature Request:</Label>
                <Input style={{backgroundColor: 'transparent', color: 'white'}} type="textarea" name="description" id="description" onChange={this.handleTextFieldChange}></Input>
              </FormGroup>
              </ModalBody>
              <ModalFooter >
                <Button color="success" style={bttnStyle} type="submit">{this.state.submit}</Button>
                <Button color="danger" style={bttnStyle} onClick={this.toggle}>Cancel</Button>
              </ModalFooter>
            </Form>
        </Modal>
      </div> 
    );
  }
}

export default IssueModals