import React from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner
} from 'reactstrap'
import VolumeInfo from '../volumeinfo/VolumeInfo'
import ImageInfo from '../imageinfo/ImageInfo'
import SnapshotInfo from '../snapshotinfo/SnapshotInfo'


class InstanceModals extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      modal: false,
      volumes: props.model.volumes,
      snapshots: props.model.snapshots,
      amis: props.model.amis,
      isLoading: true,
      comments: {}
    }
    this.toggle = this.toggle.bind(this);
    this.getImageComments = this.getImageComments.bind(this);
    this.auth = this.props.auth;
    this.axiosClient = this.props.axiosClient;
  }

  componentDidMount () {
    this.getImageComments();
  }

  getImageComments() {
    let ami_identifiers = this.state.amis.map(ami => { return ami.identifier })
    let ami_comments = {};
    this.axiosClient.get('/image/get_comments', {params: {image_ids: ami_identifiers}})
      .then(resp => {
        resp.data.comments.forEach(comment => {
          ami_comments[comment.ami_identifier] = comment.comment;
        });
        this.setState({comments: ami_comments});
        this.setState({isLoading: false});
      })
  }

  toggle () {
    this.setState({
      modal: !this.state.modal
    })
  }

  render () {
    return (
      <>
        {
          this.state.isLoading &&
          <Spinner> </Spinner>
        }
        {
          !this.state.isLoading &&
          <div>
            <Button color="secondary" style={{maxWidth: '230px', borderRadius: "24px", padding: "2px 20px",textAlign: "center",marginBottom: '50px',cursor: "pointer"}} onClick={this.toggle}>View Data</Button>
            <Modal size="xl" style={{maxWidth: '800px', width: '100%', color:'white'}} isOpen={this.state.modal} toggle={this.toggle} className={this.props.model.name}>
              <ModalHeader toggle={this.toggle} style={{color:'white', backgroundColor:'rgba(34, 46, 107, 1 )'}}>{this.props.model.name}</ModalHeader>
                <ModalBody style={{backgroundColor:'rgba(34, 46, 107, 1)', textColor:'white'}}>
                  <p>Listed below are resources associated with this instance.</p>
                  <VolumeInfo style={{color:'white'}} volumes={this.state.volumes}  />
                  <ImageInfo amis={this.state.amis} comments={this.state.comments} style={{color:'white'}}/>
                  <SnapshotInfo snapshots={this.state.snapshots} style={{color:'white'}}/>
                </ModalBody>
                <ModalFooter style={{backgroundColor:'rgba(34, 46, 107, 1)'}}>
                  <Button color="primary" onClick={this.toggle} style={{maxWidth: '230px',borderRadius: "24px",padding: "2px 20px",textAlign: "center",cursor: "pointer"}}>Close</Button>
                </ModalFooter>
            </Modal>
          </div>
        }
      </>
    )
  }
}

export default InstanceModals