import { useState, useEffect } from "react"
import axios from 'axios'
import { useAuth } from '../../lib/useAuth'
import env from "../../lib/env"

const useModelList = (modelName, setIsLoading, modelId = null, query = null, polling = false) => {
  const [modelList, setModelList] = useState(null)
  const auth = useAuth()

  let apiUrl = modelName
  if (modelId) {
    apiUrl = apiUrl + `/${modelId}`
  }

  if (query) {
    apiUrl = apiUrl + `?${query}`
  }

  console.log("API URL: ", apiUrl)

  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 20000,
    headers: auth.getAuthorizationHeaders()
  })

  useEffect(() => {
    setIsLoading(true)
    axiosClient.get(apiUrl)
      .then(resp => {
        console.log("Response: ", resp)
        setModelList(resp.data)
        setIsLoading(false)
      })
      .catch(err => {
        console.error("API Error: ", err)
        // setIsLoading(false)
      })
    if (polling) {
      setIsLoading(true)
      let i = 0;
      let invervalId = setInterval(() => {
        i++
        if (i < 30) {
          axiosClient.get(apiUrl)
          .then(resp => {
            console.log("Response: ", resp)
            setModelList(resp.data)
            setIsLoading(false)
          })
          .catch(err => {
            console.error("API Error: ", err)
            // setIsLoading(false)
          })
        } else {
          console.log("interval cleared")
          clearInterval(invervalId)
        }
      }, 30000)
    } 
  // eslint-disable-next-line
  }, [apiUrl, setIsLoading])

  return [modelList]
}

export default useModelList