import React from "react";
import { Table } from "reactstrap";

class SnapshotInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      snapshots: props.snapshots
    }
  }

  render () {
    return (
      <>
        <p><b>Snapshots</b></p>
        <Table size="sm" bordered>
        <thead>
            <tr>
              <th style={{color:'white'}}>Image ID</th>
              <th style={{color:'white'}}>Snapshot</th>
              <th style={{color:'white'}}>Device Name</th>
              <th style={{color:'white'}}>Resource Created At</th>
              <th style={{color:'white'}}>DB Record Updated At</th>
            </tr>
          </thead>
        {
          this.state.snapshots.map((snapshot, i) => {
            let data = <tr>
              <td style={{color:'white'}}>{snapshot.ami_id}</td>
              <td style={{color:'white'}}>{snapshot.identifier}</td>
              <td style={{color:'white'}}>{snapshot.device_name}</td>
              <td style={{color:'white'}}>{snapshot.resource_created}</td>
              <td style={{color:'white'}}>{snapshot.updated_at}</td>
            </tr>
            return data
          })
        }
        </Table>
      </>
    )
  }
}

export default SnapshotInfo