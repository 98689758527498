import React from "react";
import {
  Table,
} from 'reactstrap'

class VolumeInfo extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      volumes: props.volumes
    }
  }

  render () {
    return (
      <>
        <p><b>Volumes:</b></p>
        <Table size="sm" bordered>
          <thead>
            <tr>
              <th style={{color:'white'}}>Volume</th>
              <th style={{color:'white'}}>Size</th>
              <th style={{color:'white'}}>Monthly Cost</th>
              <th style={{color:'white'}}>Resource Created At</th>
            </tr>
          </thead>
          {
            this.state.volumes.map((volume, i) => {
              let monthly_cost = parseFloat(volume.cost)
              let data = <tr>
                <td style={{color:'white'}}>{volume.identifier}</td>
                <td style={{color:'white'}}>{volume.size}</td>
                <td style={{color:'white'}}>${monthly_cost.toFixed(2)}</td>
                <td style={{color:'white'}}>{volume.resource_created}</td>
              </tr>
              return data
            })
          }
        </Table>
      </>
    )
  }
}

export default VolumeInfo