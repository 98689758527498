import { useEffect } from 'react'
import { useAuth } from '../../../lib/useAuth'
import { useHistory, useLocation } from 'react-router-dom'
import qs from 'qs'

const Login = () => {
  const auth = useAuth()
  const history = useHistory()
  const location = useLocation()

  useEffect(() => {
    let params = qs.parse(location.search, { ignoreQueryPrefix: true })
    auth.setAuthorizationHeaders(params)
  // eslint-disable-next-line
  }, [location.search])

  useEffect(() => {
    if (auth.user) {
      history.push('/auth')
    }
  }, [auth.user, history])

  return <h3>Logging you in...</h3>
}

export default Login