import {
  useState
} from 'react'
import {
  Row,
  Col,
  Card,
  CardTitle,
} from 'reactstrap'
import {
  useModelList
} from '../../customHooks'
import {
  IgtLoader
} from '../../atoms'
import {
  useRouteMatch
} from 'react-router'
import '../styles/styles.css'
// import {AiOutlineHome} from 'react-icons/ai';
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";

const Environment = () => {
  const match = useRouteMatch()
  const [isLoading, setIsLoading] = useState(null)
  const [modelList] = useModelList('environments', setIsLoading, null, `jurisdiction_id=${match.params.environmentId}`)
  const [jurisdiction] = useModelList('jurisdictions', setIsLoading, match.params.environmentId)
  const [settings] = useModelList('settings', setIsLoading, 1)

  console.log("Model List: ", modelList)
  console.log("Jurisdiction:", jurisdiction)

  const calculateCostByEnvironment = (hosts) => {
    let current_monthly_cost = 0;
    let running_monthly_cost = 0;
    let stopped_monthly_cost = 0;
    hosts.forEach(host => {
      let instanceCost = 0;
      let current_number = 0;
      let running_number = 0;
      let stopped_number = 0;
      let volumeCost = 0;
      if (host.instance !== null) {
        instanceCost = host.instance.cost;
        host.volumes.forEach(volume => {volumeCost += parseFloat(volume.cost)})
        current_number += parseFloat(volumeCost)
        running_number += parseFloat(volumeCost)
        stopped_number += parseFloat(volumeCost)
        running_number += parseFloat(instanceCost)
        if (host.instance.status === "running") {
          current_number += parseFloat(instanceCost)
        }
      } else if (host.host_type === "EKS" && host.cluster !== null) {
        host.volumes.forEach(volume => {volumeCost += parseFloat(volume.cost)})
        current_number += parseFloat(volumeCost)
        current_number += parseFloat(host.cluster.cost)
        running_number += parseFloat(volumeCost)
        running_number += parseFloat(host.cluster.cost)
        stopped_number += parseFloat(volumeCost)
        stopped_number += parseFloat(host.cluster.cost)

        host.nodes.forEach(node => {
          instanceCost += node.cost
          running_number += parseFloat(instanceCost)
          if (node.status === "running") {
            current_number += parseFloat(instanceCost)
          }
        })
      }
      
      current_monthly_cost += current_number
      running_monthly_cost += running_number
      stopped_monthly_cost += stopped_number
    })

    return {
      current: current_monthly_cost.toFixed(2),
      running: running_monthly_cost.toFixed(2),
      stopped: stopped_monthly_cost.toFixed(2)
    }
  }

  const calculateInstancesRunning = (hosts, format="string") => {
    let total_instances = 0
    let running_instances = 0

    hosts.forEach(host => {
      if (host.instance !== null) {
        total_instances += 1
        if (host.instance.status === "running") {
          running_instances += 1
        }
      } else if (host.nodes.length > 0) {
        host.nodes.forEach(node => {
          total_instances += 1
          if (node.status === "running") {
            running_instances += 1
          }
        })
      }
    })
    if (format === "string") {
      return `${running_instances}/${total_instances}`
    } else {
      return running_instances
    }
    
  }

  
  return (
    <>
           
        <div>
            <Row>
              <Col lg='12' >
              {
                !isLoading && modelList !== null && modelList.length !== 0 && settings !== null && jurisdiction !== null && 
                <>
                  <BreadcrumbGroup
                    items={[
                      { 
                        text: "Home", 
                        href: "/auth" 
                      },
                      { 
                        text: (jurisdiction && jurisdiction !== null ? jurisdiction.name : "") + ` ${settings.jurisdiction}`,  
                      }
                    ]}
                    ariaLabel="Jurisdiction Environment Breadcrumbs"
                  />
                  <br/>
                </>
              }
              </Col>
            </Row>
          </div>
          {
            (isLoading || modelList === null || settings === null || jurisdiction === null) &&
            <IgtLoader />
          }
          {
            !isLoading && modelList !== null && modelList.length !== 0 && settings !== null && jurisdiction !== null &&
            <>

             {/* cover the back of entire row card  */}
              <Row >
                {
                  modelList.map((model, i) => {
                    console.log(model);
                    let hosts = model.hosts;
                    let environment_cost = calculateCostByEnvironment(hosts);
                    return (
                      <Col lg='4' key={i}>
                        <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',borderColor:'rgb(0, 123, 255)'}}>
                          <CardTitle tag='h5' style={{display: 'flex', flexDirection: 'column', justifyContent: 'left'}}>
                            <a style={{marginBottom: '10px', fontSize: '1.1em', color: '#007BFF'}} href={`/hosts/${jurisdiction.id}/${model.id}`}><strong>{model.name}</strong></a>
                            <span style={{ color: "white", marginBottom: '10px' }}>{calculateInstancesRunning(hosts)} Instances Running</span>
                            <span style={{fontSize: '.6em', color:'white', marginBottom: '5px'}} className="environment_cost">*Current Monthly Cost: ${environment_cost.current}</span>
                            {settings.show_running_stopped_cost && 
                              <>
                                <span style={{fontSize: '.6em', color:'white', marginBottom: '5px'}} className="environment_cost">Running Monthly Cost: ${environment_cost.running}</span>
                                <span style={{fontSize: '.6em', color:'white', marginBottom: '5px'}} className="environment_cost">Stopped Monthly Cost: ${environment_cost.stopped}</span>
                              </>
                            }
                          </CardTitle>
                        </Card>
                        <br/>
                      </Col>
                    )
                  })
                }
              </Row>

              {/* End of Row */}
              <Row>
                <Col lg="12">
                  <br/>
                  <i className="italics">*Environment Current Monthly Cost is an estimate based off of the AWS Pricing API, for exact cost information view the <a href="https://us-east-1.console.aws.amazon.com/billing/home?region=ca-central-1#/">AWS Billing Dashboard</a> in the console. Running and Stopped Monthly Costs are estimates of the environments cost if ALL resources in the environment are in a Running or Stopped state.</i><br/>
                </Col>
              </Row>
            </>
          }
          {
            !isLoading && modelList !== null && modelList.length === 0 &&
            <Row>
              <Col lg="12">
                <br/>
                <p>Sorry, it looks like there aren't any Environments configured for this Jurisdiction</p>
              </Col>
            </Row>
          }
    </>
  )
}

export default Environment

