import {
  PrivateRoute
} from '../../atoms'
import {
  AuthHome,
  Environment,
  Host,
  Users,
  Settings
} from '../../pages'
import {
  HouseKeeper,
  MissingTags,
  AmiCounter,
  CheckVolumes,
  WrongSizedInstances,
  WrongSizedVolumes,
  InstancesMissingAmi,
  InstanceRunPlans,
  PortalStatistics,
  InstancesNotAutomated,
  PortalSettings
} from '../../pages/house_keeper'
import {
  Row,
  Col
} from 'reactstrap'
import {
  useAuth
} from '../../../lib/useAuth'
import { NavBar } from '../../organisms'

const Auth = (props) => {
  const auth = useAuth()
  return (
    <>
      <Row>
        <Col lg="12">
          <NavBar
            permission_level={auth.user.permission_level}
            permission_scope={auth.user.permission_scope}
            userName={auth.user.name}
          />
        </Col>
      </Row>
      <Row>
        <Col lg="12">
          <PrivateRoute 
            path="/auth"
            props={props}
            component={AuthHome}
          />
          <PrivateRoute 
            path="/settings"
            props={props}
            component={Settings}
          />
          <PrivateRoute
            path="/environments/:environmentId"
            props={props}
            component={Environment}
          />
          <PrivateRoute
            path="/hosts/:jurisdictionId/:environmentId"
            props={props}
            component={Host}
          />
          <PrivateRoute
            path="/users"
            props={props}
            component={Users}
          />
          <PrivateRoute 
            path='/house-keeper/missing-tags'
            props={props}
            component={MissingTags}
          />
          <PrivateRoute 
            path='/house-keeper/ami-counter'
            props={props}
            component={AmiCounter}
          />
          <PrivateRoute 
            path='/house-keeper/check-volumes'
            props={props}
            component={CheckVolumes}
          />
          <PrivateRoute 
            path='/house-keeper/wrong-sized-instances'
            props={props}
            component={WrongSizedInstances}
          />
          <PrivateRoute 
            path='/house-keeper/wrong-sized-volumes'
            props={props}
            component={WrongSizedVolumes}
          />
          <PrivateRoute 
            path='/house-keeper/instances-missing-amis'
            props={props}
            component={InstancesMissingAmi}
          />
          <PrivateRoute 
            path='/house-keeper/instance-run-plans'
            props={props}
            component={InstanceRunPlans}
          />
          <PrivateRoute 
            path='/house-keeper/statistics'
            props={props}
            component={PortalStatistics}
          />
          <PrivateRoute 
            path='/house-keeper/unautomated'
            props={props}
            component={InstancesNotAutomated}
          />
          <PrivateRoute 
            path='/house-keeper/settings'
            props={props}
            component={PortalSettings}
          />
          <PrivateRoute 
            path='/administration'
            props={props}
            component={HouseKeeper}
          />
        </Col>
      </Row>
    </>
  )
}

export default Auth