import React from 'react';
import { 
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Form, 
  FormGroup, 
  Label, 
  Input
} from 'reactstrap';
import statusActions from '../../atoms/statusActions/StatusActions';

class HostModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false, 
      action: "",
      image: undefined,
      instance_images: undefined,
      instance_id: props.model.instance.id,
      isLoading: true,
      launchFromFinal: false,
      deleteOriginal: false,
      comment: "",
      settings: props.settings
    };
    this.auth = this.props.auth;
    this.params = { user: this.auth.user.uid };
    this.axiosClient = this.props.axiosClient;
    this.toggle = this.toggle.bind(this);
    this.handleActionChange = this.handleActionChange.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.handleTextChange = this.handleTextChange.bind(this);
    this.handleDeleteOriginalChange = this.handleDeleteOriginalChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleAction = this.handleAction.bind(this);
    this.handleEnter = this.handleEnter.bind(this);
    this.statusStyle = statusActions.defineStatusStyle(this.props.model.instance.status);
  }

  componentDidMount() {
    this.getImages(this.state.instance_id);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleActionChange(e) {
    this.setState({ action: e.target.value });
  }

  handleTextChange(e) {
    let field = e.target.id;
    let value = e.target.value;
    this.setState({ [field]: value} );
  }

  handleImageChange(e) {
    let image_info = e.target.value.split("_");
    let imageId = image_info[0];
    let isFinal = image_info[1] === "final" ? true : false
    if (isFinal === false && this.state.deleteOriginal === true) {
      this.setState({
        deleteOriginal: false
      });
    }
    this.setState({ 
      image: imageId,
      launchFromFinal: isFinal 
    });
  }

  handleDeleteOriginalChange(e) {
    this.setState({ deleteOriginal: e.target.checked });
  }

  handleAction() {
    console.log("Action State: " + this.state.action);
    console.log("Instance ID: " + this.state.instance_id);
    if (this.state.action === "start") {
      this.startInstance(this.state.instance_id);
    } else if (this.state.action === "stop") {
      this.stopInstance(this.state.instance_id);
    } else if (this.state.action === "backup") {
      this.createImage(this.state.instance_id);
    } else if (this.state.action === "terminate") {
      this.terminateInstance(this.state.instance_id);
    } else if (this.state.action === "launch") {
      this.launchInstance(this.state.instance_id, this.state.image);
    } else {
      alert("Invalid Action");
    }
  }

  handleEnter(e) {
    if (e.key === 13) {
      this.handleAction();
    }
  }

  handleSubmit(e) {
    e.preventDefault();
    console.log(e);
    this.handleAction();
  }

  startInstance(instance_id) {
    this.axiosClient.post(`/instances/${instance_id}/start`, this.params)
      .then(resp => {
        console.log("API Response: ", resp)
        this.toggle();
        alert('Instance Start Queued!')
        window.location.reload(false)
      })
      .catch(err => {
        console.error("API Error: ", err)
        this.toggle();
        alert("Instance Start could not be queued, please try again later.")
        window.location.reload(false)
      })
  }

  stopInstance(instance_id) {
    this.axiosClient.post(`/instances/${instance_id}/stop`, this.params)
      .then(resp => {
        console.log("API Response: ", resp)
        this.toggle();
        alert('Instance Stop Queued!')
        window.location.reload(false)
      })
      .catch(err => {
        console.error("API Error: ", err)
        this.toggle();
        alert("Instance Stop could not be queued, please try again later.")
        window.location.reload(false)
      })
  }

  createImage(instance_id) {
    this.params.comment = this.state.comment
    this.axiosClient.post(`/image/${instance_id}/create`, this.params)
      .then(resp => {
        console.log("Image Creation API Response: ", resp)
        this.toggle();
        alert('Image Creation Queued!')
        window.location.reload(false)
      })
      .catch(err => {
        console.error("API Error: ", err)
        this.toggle();
        alert("Image creation could not be queued, please try again later.")
        window.location.reload(false)
      })
  }

  terminateInstance(instance_id) {
    this.params.comment = this.state.comment
    this.axiosClient.post(`/instances/${instance_id}/terminate`, this.params)
      .then(resp => {
        console.log("Instance Termination API Response: ", resp)
        this.toggle();
        alert('Instance Termination Queued!')
        window.location.reload(false)
      })
      .catch(err => {
        console.error("API Error: ", err)
        this.toggle();
        alert("Instance Termination could not be queued, please try again later.")
        window.location.reload(false)
      })
  }

  launchInstance(instance_id, ami_id) {
    this.params.delete_original = this.state.deleteOriginal;
    this.axiosClient.post(`/instances/${instance_id}/launch/${ami_id}`, this.params)
      .then(resp => {
        console.log("Instance Launch API Response: ", resp);
        this.toggle();
        alert('Instance Launch Queued');
        window.location.reload(false)
      })
      .catch(err => {
        console.error("API Error: ", err)
        this.toggle();
        alert("Instance Launch could not be queued, please try again later.")
        window.location.reload(false)
      })
  }

  getImages(instance_id) {
    this.axiosClient.get(`/image/${instance_id}/get`)
      .then(resp => {
        this.setState({instance_images: resp.data.images})
        this.setState({isLoading: false})
      })
  }

  LaunchInputSelection(props) {
    let instance_images = props.instance_images;
    let backup_images = instance_images.filter(image =>  {return image.auto_backup === true})
    let portal_images = instance_images.filter(image =>  {return image.auto_backup === false})
    let sorted_images = portal_images.sort((a,b) => new Date(a.resource_created) - new Date(b.resource_created));
    let launch_options = sorted_images.map((image, i) => {
      return <option value={image.id + (i === 0 ? "_original" : "_final")}>{image.identifier} - {i === 0 ? "Original" : "Final"} (Created: {image.resource_created})</option>
    })
    if (backup_images.length > 0) {
      let backup_options = backup_images.map(image => {
        return <option value={image.id + "_backup"}>{image.identifier} - Backup (Created: {image.resource_created})</option>
      })
      launch_options = launch_options.concat(backup_options);
    }
    console.log("sorted options: ", launch_options);

    return <>
      {
        launch_options.map(option => {
          return option;
        })
      }
    </>
  }

  ActionOptions(props) {
    if (props.status === 'stopped') {
      return <>
        <option value="start">Start</option>
        {props.settings.allow_terminate && (
          <option value="terminate">Terminate</option>
        )}
        {props.settings.allow_backup && (
          <option value="backup">Backup</option>
        )}
        </>
    } else if (props.status === 'running') {
      return <>
        <option value="stop">Stop</option> 
        {props.settings.allow_backup && (
          <option value="backup">Backup</option>
        )}
      </>
    } else if (props.status === 'terminated') {
      return <>
        <option value="launch">Launch from Image</option>
      </>
    } else {
      return <>
        <option>No Available Actions at this Time</option>
      </>
    }
  }

  render() {

    const modalColor = {
      backgroundColor:'rgba(27, 32, 99, 1)',
      color:'white'
    }

    const bttnStyle = {
      borderRadius: "24px",
      border: "2px",
      padding: "5px 20px",
      textAlign: "center",
      display: "inline-block",
      margin: "4px 9px",
    }

    return (
      <div>
        <Button color="success" 
        style={{maxWidth: '230px', 
                    borderRadius: "24px", 
                    padding: "2px 20px", 
                    textAlign: "center",
                    marginBottom: '50px',
                    cursor: "pointer"}} onClick={this.toggle}>Actions</Button>
        <Modal style={modalColor} isOpen={this.state.modal} toggle={this.toggle} className={this.props.model.name}>
          <ModalHeader style={modalColor} toggle={this.toggle}>{this.props.model.name}</ModalHeader>
          <Form style={modalColor} onSubmit={this.handleSubmit}>
            <ModalBody>
              <p>Select the action you would like to initiate on <i>{this.props.model.name}</i>.</p>
              <p>Status: <span style={this.statusStyle}>{this.props.model.instance.status}</span></p>
              <FormGroup>
                <Label>Action:</Label>
                <Input type="select" name="action" id="action" onChange={this.handleActionChange}>
                  <option>-- Please Select an Action --</option>
                  <this.ActionOptions status={this.props.model.instance.status} instance_id={this.state.instance_id} settings={this.state.settings} />
                </Input>
                <Input type="hidden" value={this.props.model.instance.id}></Input>
              </FormGroup>
              {this.props.model.instance.status === "terminated" && (
                <FormGroup>
                  <Label>Select the image to launch from:</Label>
                  <Input type="select" name="image" id="image" onChange={this.handleImageChange}>
                    <option>-- Please Select an Image to Launch From --</option>
                    <this.LaunchInputSelection instance_images={this.state.instance_images} />
                  </Input>
                </FormGroup>
              )}
              {this.state.launchFromFinal === true && this.props.model.instance.status === "terminated" && (
                <FormGroup check>
                  <Input  type="checkbox" name="deleteOriginal" id="deleteOriginal" onChange={this.handleDeleteOriginalChange}/>
                  {' '}
                  <Label check>
                    Delete Original Image and Make Final Image the New Original?
                  </Label>
                </FormGroup>
              )}
              {(this.state.action === "backup" || this.state.action === "terminate") && (
                <FormGroup>
                  <Label for="comment">Comments (Not Required)</Label>
                  <Input type="text" name="comment" id="comment" placeholder="Enter short comment here" onChange={this.handleTextChange}/>
                </FormGroup>
              )}
            </ModalBody>
            <ModalFooter>
              <Button style={bttnStyle} color="success">Initiate Action</Button>
              <Button style={bttnStyle} color="danger" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </div> 
    );
  }
}

export default HostModals