import React from 'react'
import { useEffect, useState } from 'react'
import {
  Container,
  Row,
  Col,
  Card,
  CardTitle,
  CardText,
  Table,
  Button
} from 'reactstrap'
import {
  useAuth
} from '../../../lib/useAuth'
import axios from 'axios'
import './style/house_keeper.css'
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import env from '../../../lib/env'

const HouseKeeper = () => {
  const auth = useAuth()
  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 20000,
    headers: auth.getAuthorizationHeaders()
  })

  const [authorized, setAuthorization] = useState(true)

  useEffect(() => {
    if (auth.user.permission_level !== 'admin') {
      setAuthorization(false)
    }
    //eslint-disable-next-line
  }, [])

  const syncAll = () => {
    axiosClient.post(`/sync/all`)
      .then(resp => {
        console.log('API Response: ', resp)
        alert('Resource Sync Queued')
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  const resetAll = () => {
    axiosClient.post(`/sync/reset`)
    .then(resp => {
      console.log('API Response: ', resp)
      alert('Resource Reset Queued')
    })
    .catch(error => {
      console.log(error.response)
    })
  }
  const txtColor ={
    color: 'white'
  }

  return (
    <>
      <Container fluid>
        <br/>
        <Row>
          <Col lg='12'>
            <BreadcrumbGroup
            items={[
              { 
                text: "Home", 
                href: "/auth" 
              },
              { 
                text: "Administration"
              }
            ]}
            ariaLabel="Administration Breadcrumbs"
          />
          </Col>
        </Row>
        <br/>
        {
          !authorized &&  (
            <Col>You are not authorized to view this page</Col>
          )
        }
        {
          auth.user.permission_level === "admin" && auth.user.permission_scope === "global" && (
            <>
              <Row>
                <Col lg='12'>
                  <Table bordered >
                    <thead>
                      <tr>
                        <th style={txtColor}>Action</th>
                        <th style={txtColor}>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td><Button color="primary" block onClick={syncAll}>&#8635; Sync All Resources</Button></td>
                        <td style={txtColor}>This will search through the AWS Account and add all Resources to the Database that have the tags igtMigration:include and igtMigration:environmentName.</td>
                      </tr>
                      <tr>
                        <td><Button color="danger" block onClick={resetAll}>&#8635; Reset All Resources</Button></td>
                        <td style={txtColor}>This will delete all resource database records and run a fresh resource sync on the AWS account. Only for use when issues arise with the regular resource sync. </td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/ami-counter'>AMI Counter</a>
                    </CardTitle>
                    <CardText style={txtColor}>See which AMIs have more than X number of versions.</CardText>
                  </Card>
                </Col>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/wrong-sized-instances'>Wrong Sized Instances</a>
                    </CardTitle>
                    <CardText style={txtColor}>See which EC2 instances are incorrectly sized.</CardText>
                  </Card>
                </Col>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/instances-missing-amis'>Instances Missing AMI</a>
                    </CardTitle>
                    <CardText style={txtColor}>See which EC2 instances are missing an AMI.</CardText>
                  </Card>
                </Col>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/missing-tags'>Missing Tags</a>
                    </CardTitle>
                    <CardText style={txtColor}>See which resources are missing the mandatory tags.</CardText>
                  </Card>
                </Col>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/wrong-sized-volumes'>Wrong Sized Volumes</a>
                    </CardTitle>
                    <CardText style={txtColor}>See which EBS volumes are incorrectly sized.</CardText>
                  </Card>
                </Col>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/check-volumes'>Check Volumes</a>
                    </CardTitle>
                    <CardText style={txtColor}>View details about the provided instance-id's volumes.</CardText>
                  </Card>
                </Col>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/instance-run-plans'>Instance Run Plans</a>
                    </CardTitle>
                    <CardText style={txtColor}>View run plan information about the recently launched instances.</CardText>
                  </Card>
                </Col>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/statistics'>Portal Statistics</a>
                    </CardTitle>
                    <CardText style={txtColor}>Information on all of the resources managed via the portal.</CardText>
                  </Card>
                </Col>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/unautomated'>Instances Not Configured For Automation</a>
                    </CardTitle>
                    <CardText style={txtColor}>List all of the Instances missing the AUTOPOWER_ENV or AUTOPOWER_ORDER tags.</CardText>
                  </Card>
                </Col>
                <Col lg='6' className="Column_Spacer">
                  <Card body style={{backgroundColor:'rgba(27, 32, 99, 0.9)',boxShadow: 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px', borderColor:'rgb(0, 123, 255)'}}>
                    <CardTitle tag='h4'>
                      <a href='/house-keeper/settings'>Portal Settings</a>
                    </CardTitle>
                    <CardText style={txtColor}>Settings for configuring your Cloud Management Portal environment.</CardText>
                  </Card>
                </Col>
              </Row>
              <br/>
              <br/>
              <br/>
            </>
        )}
      </Container>
    </>
  )
}

export default HouseKeeper
