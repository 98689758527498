import React from 'react'
import {
  BrowserRouter as Router,
  Switch
} from "react-router-dom"
import {
  PrivateRoute,
  PublicRoute
} from '../atoms'
import {
  PageNotFound,
  Auth,
  Unauth
} from '../ecosystems'
import {
  Login
} from '../pages'

const Routes = (props) => {
  return (
    <Router>
      <Switch>
        <PrivateRoute 
          path="/auth"
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path="/settings"
          props={props}
          component={Auth}
        />
        <PrivateRoute
          path="/environments/:environmentId"
          props={props}
          component={Auth}
        />
        <PrivateRoute
          path="/hosts/:jurisdictionId/:environmentId"
          props={props}
          component={Auth}
        />
        <PrivateRoute
          path="/users"
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/missing-tags'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/ami-counter'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/check-volumes'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/wrong-sized-instances'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/wrong-sized-volumes'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/instances-missing-amis'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/instance-run-plans'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/statistics'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/unautomated'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/house-keeper/settings'
          props={props}
          component={Auth}
        />
        <PrivateRoute 
          path='/administration'
          props={props}
          component={Auth}
        />
        <PublicRoute 
          path="/login"
          props={props}
          component={Login}
        />
        <PublicRoute
          path="/:incorrect_route"
          props={props}
          component={PageNotFound}
        />
        <PublicRoute 
          path="/"
          props={props}
          component={Unauth}
        />
      </Switch>
    </Router>
  )
}

export default Routes