import {
  Navbar,
  Nav,
  NavItem,
  NavbarText,
  NavbarBrand,
  NavLink,
  Button
} from 'reactstrap'
import {AiOutlineHome, AiOutlineSetting, AiOutlineUser, AiOutlineContainer} from 'react-icons/ai';
import env from '../../../lib/env'

const NavBar = ({ permission_level, permission_scope, userName }) => {
  console.log(permission_level, permission_scope)
  const environment = env.REACT_APP_AWS_TITLE
  const showImages = environment === "Casino" || environment === "Staging" || environment === "LOCALHOST" ? true : false

  const navBar = {
    backgroundColor: "transparent",
    marginTop:'20px',
    marginBottom:'30px'
  }

  const navBttn = {
    border: "0px",
    color: "white",
    backgroungColor: "transparent",
    marginBottom:'10px'
  }

  const navText = {
    border: "0px",
    color: "white",
    backgroungColor: "transparent",
    marginBottom:'5px',
    fontSize: '.8em',
    lineHeight: '.5'
  }

  return (
    <>
      <Navbar justify variant="tabs"  style={navBar} defaultActiveKey="/home"  light full expand="md"> 
        <NavbarBrand href="/auth">
          <div>
          <img
                alt="Logo"
                src="/logo.png"
                href="/auth"
                style={{maxHeight: '60px', maxWidth: '60px', marginRight:'5px', marginTop:'-8px'}}
              />
            <b style={{color:'white',}} className="title">{environment} Cloud Management Portal</b>
          </div>
        </NavbarBrand>
        <Nav className="mr-auto" navbar>
          <NavItem>
            <NavLink href="/auth" exact activeClassName="active-link">
              <span>
                <Button style={navBttn} color="transparent" >
                    <AiOutlineHome size={24}/> Home
                  </Button>
              </span>
            </NavLink>
          </NavItem>      
          {
            showImages &&  

            <NavItem>
                <NavLink href="http://192.168.128.233:8086/" target='_blank'>
                <Button style={navBttn} color="transparent">
                    <AiOutlineContainer size={24}/> Images
                  </Button>
                </NavLink>
            </NavItem>
          } 
          {
            permission_level === "admin" && permission_scope === "global" && 

            <NavItem>
                <NavLink href="/users">
                <Button style={navBttn} color="transparent">
                    <AiOutlineUser size={24}/> Users
                  </Button>
                </NavLink>
            </NavItem>
          } 
          {
            permission_level === "admin" && permission_scope === "global" &&
              <NavItem >
                <NavLink href="/administration" exact activeClassName="active-link">

                    <Button  color="transparent" style={navBttn}>
                      <AiOutlineSetting size={24}/> Administration
                    </Button>
                  
                </NavLink>
              </NavItem>
          }
        </Nav>
        <NavbarText style={{lineHeight: '1.1'}}>
          <span style={navText}  className="nav-text">Welcome {userName}!</span><br/>
          <span style={navText} className="nav-text"><strong>ENV:</strong> {env.REACT_APP_AWS_ENVIRONMENT} </span><br/>
          <span style={navText} className="nav-text"><strong>ACT</strong>: {env.REACT_APP_AWS_ACCOUNT_NAME} #{env.REACT_APP_AWS_ACCOUNT_NUMBER}</span><br/>
          <span style={navText} className="nav-text"><strong>DEFAULT COST CENTER</strong>: {env.REACT_APP_AWS_DEFAULT_CC}</span>
        </NavbarText>
      </Navbar> 
    </>
  )
}


export default NavBar