import {
  Button,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle
} from 'reactstrap'
import env from '../../../lib/env'

const UnauthHome = () => {
  const redirectToSignIn = () => {
    window.location.replace(env.REACT_APP_SIGN_IN_URL)
  }
  const environment = env.REACT_APP_AWS_ACCOUNT_NAME

  const boxColor = {
    backgroundColor:'rgba(27, 32, 99, 0.4)',
    borderColor:'rgb(0, 123, 255)',
  }
  return (
    <>
      <Row  >
        <Col  lg={{ size: 4, offset: 4 }} style={{display: 'flex', flexDirection: 'column', marginTop: '15%'}}>
          <Card style={boxColor}>
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', margin: '15px'}}>
              <img
                alt="Logo"
                src="logo.png"
                style={{maxHeight: '126px', maxWidth: '126px'}}
              />
            </div>
            <CardBody >
              <CardTitle tag='h4' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', textAlign: 'center', color:'white'}}>
                Welcome to the {environment} Cloud Management Portal
              </CardTitle>
              <CardSubtitle tag='h5' style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', color:'white'}}>
                Click login to begin!
              </CardSubtitle>
              <Button color= 'primary' style={{marginTop: '20px',borderRadius: "24px", padding: "2px 20px", textAlign: "center"}} block onClick={redirectToSignIn}>Login</Button>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </>
  )
}

export default UnauthHome