import { Container, Row, Col } from 'reactstrap'
import Routes from '../../routes'
import { ProvideAuth } from '../../../lib/useAuth'

const App = () => {
  return (
    <ProvideAuth>
      <Container fluid>
        <Row>
          <Col lg='12'>
            <Routes />
          </Col>
        </Row>
      </Container>
    </ProvideAuth>
  )
}

export default App
