import {
  Row,
  Table, 
  Button
} from 'reactstrap'
import axios from 'axios'
import {
  useAuth
} from '../../../lib/useAuth'
import env from '../../../lib/env'

const Settings = () => {
  const auth = useAuth()
  console.log(auth.user)
  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 20000,
    headers: auth.getAuthorizationHeaders()
  })

  const syncAll = () => {
    axiosClient.post(`/sync/all`)
      .then(resp => {
        console.log('API Response: ', resp)
        alert('Resource Sync Queued')
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  const resetAll = () => {
    axiosClient.post(`/sync/reset`)
    .then(resp => {
      console.log('API Response: ', resp)
      alert('Resource Reset Queued')
    })
    .catch(error => {
      console.log(error.response)
    })
  }

  
  return (
    <>
      <br/>
      <Row>
        <h3> Utilities </h3>
      </Row>
      <br/>
      {
        auth.user.permission_level === "admin" && auth.user.permission_scope === "global" &&
        
        <Row>
          <Table bordered>
            <thead>
              <tr color='white'>
                <th >Action</th>
                <th>Description</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td><Button color="primary" block onClick={syncAll}>&#8635; Sync All Resources</Button></td>
                <td> This will search through the AWS Account and add all Resources to the Database that have the tags igtMigration:include and igtMigration:environmentName.</td>
              </tr>
              <tr>
                <td><Button color="danger" block onClick={resetAll}>&#8635; Reset All Resources</Button></td>
                <td>This will delete all resource database records and run a fresh resource sync on the AWS account. Only for use when issues arise with the regular resource sync. </td>
              </tr>
            </tbody>
          </Table>
        </Row>
      }
    </>
  )

}

export default Settings