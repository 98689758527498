/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css, keyframes } from '@emotion/react'
import {
  Container,
  Row,
  Col
} from 'reactstrap'

const IgtLoader = () => {
  const right = keyframes`
  0% {transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg)}
  100% {transform: rotateX(180deg) rotateY(360deg) rotateZ(360deg)}
  `

  const left = keyframes`
  0% {transform: rotateX(0deg) rotateY(0deg) rotateZ(0deg)}
  100% {transform: rotateX(360deg) rotateY(180deg) rotateZ(360deg)}
  `
  return (
    <Container fluid>
      <Row style={{ marginTop: '10%' }}>
        <Col lg={{ size: 2, offset: 5}}>
          <div css={css`
            height: 150px;
            width: 150px;
            position: relative;
          `}>
            <div css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 150px;
              height: 150px;
              border: 15px solid #ffa300;
              opacity: 1;
              border-radius: 100%;
              animation-fill-mode: forwards;
              perspective: 800px;
              animation: ${right} 2s 0s infinite linear;
              `} />
            <div css={css`
              position: absolute;
              top: 0;
              left: 0;
              width: 150px;
              height: 150px;
              border: 15px solid #0c51a1;
              opacity: 1;
              border-radius: 100%;
              animation-fill-mode: forwards;
              perspective: 800px;
              animation: ${left} 2s 0s infinite linear;
              `} />
          </div>
        </Col>
      </Row>
    </Container>
  )
}

export default IgtLoader