import React, { useEffect, useState } from 'react'
import {
  Col,
  Container,
  Row,
  Table
} from 'reactstrap'
import {
  useAuth
} from '../../../lib/useAuth'
import SimpleLoader from "./SimpleLoader"
import axios from 'axios'
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";

import env from '../../../lib/env'

const InstanceRunPlans = () => {
  const auth = useAuth()
  console.log(auth.user)

  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 20000,
    headers: auth.getAuthorizationHeaders()
  })

  const [tableData, setTableData] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axiosClient.get(`http://localhost:3000/housekeeper/instance_run_plans`)
      .then(resp => {
        setIsLoading(false)
        setTableData(resp.data)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const tableStyles = {
    color: 'white',
    backgroundColor:'rgba(27, 32, 99, 0.9)',
    borderColor:'rgb(0, 123, 255)',
    borderRadius: "5px",
  }
  
  return (
    <Container fluid>
      <Row>
        <Col lg='12'>
          <BreadcrumbGroup
            items={[
              { 
                text: "Home", 
                href: "/auth" 
              },
              { 
                text: "Administration", 
                href: "/administration"
              },
              {
                text: "Instance Run Plans"
              }
            ]}
            ariaLabel="Instance Run Plans Environment Breadcrumbs"
          />
        </Col>
        <br />
        <br />
      </Row>
      {auth.user.permission_level !== "admin" && (
        <Col style={{color: 'white'}}>You are not authorize to view this page</Col>
      )}
      {auth.user.permission_level === "admin" && auth.user.permission_scope === "global" && (
        <>
          <Row>
          </Row>
          <Row>
            <Col style={{color: 'white'}} lg={{ size: 6, offset: 3 }}>
              <h1 className="text-center">Instance Run Plans</h1>
            </Col>
          </Row>

          <Row>
            <Col lg='10' style={{margin:'auto'}}>
              {
                isLoading ?
                  <SimpleLoader
                    type="Oval"
                    isLoading={isLoading}
                  />
                  :
                  <Table style={tableStyles}>
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Server Name</th>
                        <th>Instance Id</th>
                        <th>Run Plan</th>
                        <th>Launch Time</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        tableData && tableData.map((obj, i) => {
                          return (
                            <tr key={i}>
                              <td>{i+1}</td>
                              <td>{obj['name']}</td>
                              <td>{obj['id']}</td>
                              <td>{obj['run_plan']}</td>
                              <td>{obj['launch_time']}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
              }
            </Col>
          </Row>
        </>
      )}
    </Container>
  )
}

export default InstanceRunPlans
