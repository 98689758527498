import {
  Row,
  Col,
  Table
} from 'reactstrap'
import {
  useModelList
} from '../../customHooks'
import {
  IgtLoader
} from '../../atoms'
import {
  useAuth
} from '../../../lib/useAuth'
import { useEffect, useState } from 'react'
import UserModals from '../../molecules/usermodals/UserModals'
// import Table from "@cloudscape-design/components/header";


const Users = (props) => {
  const auth = useAuth()
  const [isLoading, setIsLoading] = useState(true)
  const [modelList] = useModelList('users', setIsLoading, null, null)
  modelList?.sort((a, b) => a.email.toLowerCase().localeCompare(b.email.toLowerCase()))
  const [jurisdictions] = useModelList('jurisdictions', setIsLoading, null, null)
  const [settings] = useModelList('settings', setIsLoading, 1)
  
  const [authorized, setAuthorization] = useState(true)

  const myStyle = {
     color:"white",
     borderbottom: "1px solid white",
     fontFamily: "sans-serif",
     backgroundColor:'rgba(27, 32, 99, 1)',
     borderColor:'rgb(0, 123, 255)',
     borderRadius: "5px",
     
  };

  const userTitle= {
    color:"white",
  }

  const column= {
    margin: "auto"
  }
  
  useEffect(() => {
    console.log(auth.user.uid)
    if (auth.user.permission_level !== 'admin' && auth.user.uid !== "blake.borgholthaus@igt.com" & auth.user.uid !== "tanner.badalato@igt.com") {
      console.log("not working")
      setAuthorization(false)
    }
    //eslint-disable-next-line
  }, [])

  return (
    <>
      <Row>
        <Col style={column} lg="10">
          <br />
          <h1 style={userTitle}>Users</h1>
          <br />
          <br />
        </Col>
      </Row>
      <Row>
        <Col style={column} lg="10">
        {
          isLoading && authorized && (jurisdictions === null || settings === null || modelList === null) && 
          <IgtLoader isLoading={isLoading} />
        }
        {
          isLoading && !authorized &&
          <Row>
            <Col><p>You are not authorized to view this page.</p></Col>
          </Row>
        }
        {
          !isLoading && modelList !== null && settings !== null && jurisdictions !== null &&

          <Table style={column}>
          
            <thead style={myStyle}>
              <tr >
                <th>E-mail </th>
                <th>Name</th>
                <th>Permission Level</th>
                <th>Permission Scope</th>
                <th>{settings.jurisdiction}</th>
                <th>Edit</th>
              </tr>
            </thead>
            <tbody >
              {
                modelList.map((user) => {
                  return (
                    
                    <tr style={myStyle} key={user.id} >
                      <td>{user.email}</td>
                      <td>{user.name}</td>
                      <td>{user.permission_level}</td>
                      <td>{user.permission_scope}</td>
                      <td>{user.allowed_jurisdictions.length > 0 ? user.allowed_jurisdictions.toString() : settings.restrict_jurisdictions === false ? "All" : "None"}</td>
                      <td>
                        <UserModals
                          userName={user.name}
                          permissionLevel={user.permission_level}
                          permissionScope={user.permission_scope}
                          allowedJurisdictions={user.allowed_jurisdictions}
                          userId={user.id}
                          allJurisdictions={jurisdictions}
                          auth={auth}
                          jurisdictionSetting={settings.jurisdiction}
                        />
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        }
        
        </Col>
      </Row>
    </>
  )
}

export default Users

