import { PublicRoute } from '../../atoms'
import { UnauthHome } from '../../pages'

const Unauth = () => {
  return (
    <>
      <PublicRoute
        exact
        path="/"
        component={UnauthHome}
      />
    </>
  )
}

export default Unauth