import React from 'react';
import { 
  Button, 
  Modal, 
  ModalHeader, 
  ModalBody, 
  ModalFooter, 
  Form,
  Table,
  Spinner,
  Col,
  Row,
  Label, 
  Input
} from 'reactstrap';
import statusActions from '../../atoms/statusActions/StatusActions';
import axios from 'axios';
import env from '../../../lib/env';

class ScheduleModals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: false, 
      schedules: {},
      isLoading: true,
      isSubmitting: false
    };
    this.auth = this.props.auth;
    this.environment = this.props.environment;
    this.hosts = this.props.models;
    this.hosts_by_schedule = this.sortHostsBySchedule(this.hosts)
    this.params = { user: this.auth.user.uid };
    this.axiosClient = this.props.axiosClient;
    this.toggle = this.toggle.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleBeginTimeChange = this.handleBeginTimeChange.bind(this);
    this.handleEndTimeChange = this.handleEndTimeChange.bind(this);
    this.handleStartDayChange = this.handleStartDayChange.bind(this);
    this.handleEndDayChange = this.handleEndDayChange.bind(this);
    this.isValidSchedule = this.isValidSchedule.bind(this);
  }

  isValidSchedule(host) {
    return host.schedule !== "Unknown" && host.schedule !== null && host.schedule.length > 0 && host.schedule !== 'scheduleInactive'
  }
 
  sortHostsBySchedule(hosts) {
    let hosts_by_schedule = []
    hosts.forEach(host => {
      if(this.isValidSchedule(host)) {
        hosts_by_schedule[host.schedule] = [];
      } else {
        hosts_by_schedule["Unscheduled"] = [];
      }
    });
    hosts.forEach(host => {
      if(this.isValidSchedule(host)) {
        hosts_by_schedule[host.schedule].push(host);
      } else {
        hosts_by_schedule["Unscheduled"].push(host);
      }
    });
    return hosts_by_schedule;
  }

  componentDidMount() {
    let params = this.checkForValidSchedules();
    if (params !== false) {
      const axiosClient = axios.create({
        baseURL: env.REACT_APP_BASE_API_URL,
        timeout: 200000,
        headers: this.auth.getAuthorizationHeaders(),
        params: params
      });
      axiosClient.get('/schedules')
      .then(resp => {
        let schedules = this.parseAwsSchedules(resp.data);
        this.setState({schedules: schedules});
        this.setState({isLoading: false});
      })
      .catch(e => {
        console.error(e);
      })
    }
  }

  parseAwsSchedules(data) {
    let parsed_schedules = {};
    data.forEach(schedule => {
      parsed_schedules[schedule.name.toUpperCase()] = schedule;
    })
    return parsed_schedules;
  }

  checkForValidSchedules() {
    let unpparsed_schedules = Object.keys(this.hosts_by_schedule);
    let schedules = unpparsed_schedules.filter(schedule => (schedule !== "Unknown" && schedule !== "Unscheduled" && schedule !== 'null' && schedule.length > 0 && schedule !== 'scheduleInactive'))
    return schedules.length > 0 ? { schedules } : false;
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleSubmit(e) {
    e.preventDefault();
    this.setState({isSubmitting: true});
    let params = {schedules: Object.keys(this.state.schedules).map(key => {return this.state.schedules[key]})};
    const axiosClient = axios.create({
      baseURL: env.REACT_APP_BASE_API_URL,
      timeout: 200000,
      headers: this.auth.getAuthorizationHeaders(),
      params: params
    });
    axiosClient.post('/schedules')
      .then(resp => {
        console.log("API Response: ", resp);
        this.setState({isSubmitting: false});
        this.toggle();
        alert('Schedules have been updated!');
        window.location.reload(false);
      })
      .catch(err => {
        console.error("API Error: ", err)
        this.toggle();
        alert("Schedule could not be updated, please submit a support ticket with this environments info from the homepage")
        window.location.reload(false)
      });
  }

  doesHaveScheduledInstances(hosts) {
    let isScheduled = false;
    hosts.forEach(host => {
      if (host.schedule !== 'Unknown' && host.schedule !== null) {
        isScheduled = true
      }
    })
    return isScheduled;
  }

  ScheduledInstances (hosts) {
    const modalColor = {
      backgroundColor:'rgba(27, 32, 99, 1)',
      color:'white'
    }
    return <>
      <Table style={modalColor}>
        <thead>
          <th>Name</th>
          <th>Identifier</th>
          <th>Status</th>
        </thead>
        { hosts.hosts.map((host, i) => {
          let host_data = <></>
          if (host.instance !== null) {
            let statusStyle = statusActions.defineStatusStyle(host.instance.status);
            host_data = <tr>
              <td>{host.name}</td>
              <td>{host.instance.identifier}</td>
              <td style={statusStyle}>{host.instance.status}</td>
            </tr>
          } 
          return host_data;
        })}
      </Table>
    </>
  }

  handleBeginTimeChange(e) {
    let schedules = this.state.schedules
    schedules[e.target.id].begintime = e.target.value;
    this.setState({schedules: schedules});
  }

  handleEndTimeChange(e) {
    let schedules = this.state.schedules
    schedules[e.target.id].endtime = e.target.value;
    this.setState({schedules: schedules});
  }

  handleStartDayChange(e) {
    let schedules = this.state.schedules;
    let weekdays = schedules[e.target.id].weekdays[0].split('-');
    let beginday = e.target.value;
    let endday = weekdays[1];
    schedules[e.target.id].weekdays = [`${beginday}-${endday}`];
    this.setState({schedules: schedules});
  }

  handleEndDayChange(e) {
    let schedules = this.state.schedules;
    let weekdays = schedules[e.target.id].weekdays[0].split('-');
    let beginday = weekdays[0];
    let endday = e.target.value;
    schedules[e.target.id].weekdays = [`${beginday}-${endday}`];
    this.setState({schedules: schedules});
  }

  ScheduleFormElements(data) {

    const modalColor = {
      backgroundColor:'rgba(27, 32, 99, 1)',
      color:'white'
    }
    let schedule = data.schedule
    let state_schedules = data.available_schedules;
    if (state_schedules[schedule]) {
      let timezone = state_schedules[schedule].timezone;
      let weekdays = state_schedules[schedule].weekdays[0].split('-');
      let beginDay = weekdays[0];
      let endDay = weekdays[1];
      const beginTimeChange = data.beginTimeChange;
      const endTimeChange = data.endTimeChange;
      const startDayChange = data.startDayChange;
      const endDayChange = data.endDayChange;
      const days = [
        {key: "mon", value: "Monday"}, 
        {key: "tues", value: "Tuesday"},
        {key: "wed", value: "Wednesday"},
        {key: "thurs", value: "Thursday"},
        {key: "fri", value: "Friday"},
        {key: "sat", value: "Saturday"},
        {key: "sun", value: "Sunday"},
      ];
      return <>
        <Row style={modalColor}>
          <Col lg="12" style={{paddingBottom: "10px"}}>
            <b>Schedule Timezone:</b> {timezone}
          </Col>
        </Row>
        <Row style={modalColor}>
          <Col lg="6">
            <Label for={"begin-" + schedule}><b>Begin Time:</b></Label>
            <Input style={{backgroundColor: 'transparent', color: 'white'}} type="time" name={"begin-" + schedule} id={schedule} value={state_schedules[schedule].begintime} onChange={beginTimeChange}></Input>
          </Col>
          <Col lg="6">
            <Label for={"end-" + schedule}><b>End Time:</b></Label>
            <Input style={{backgroundColor: 'transparent', color: 'white'}} type="time" name={"end-" + schedule} id={schedule} value={state_schedules[schedule].endtime} onChange={endTimeChange}></Input>
          </Col>
        </Row>
        <Row style={modalColor}>
          <Col lg="6">
            <Label for={"beginday-" + schedule}><b>Begin Day:</b></Label>
            <Input style={{backgroundColor: 'transparent', color: 'white'}} type="select" id={schedule} name={"beginday-" + schedule} onChange={startDayChange}>
              {days.map(day => {
                if (beginDay === day.key) {
                  return (<option key={day.key} value={day.key} selected>{day.value}</option>)
                } else {
                  return (<option key={day.key} value={day.key}>{day.value}</option>)
                }
              })}
            </Input> 
          </Col>
          <Col lg="6">
            <Label for={"endday-" + schedule}><b>End Day:</b></Label>
            <Input style={{backgroundColor: 'transparent', color: 'white'}} type="select" id={schedule} name={"endday-" + schedule} onChange={endDayChange}>
              {days.map(day => {
                if (endDay === day.key) {
                  return (<option key={day.key} value={day.key} selected>{day.value}</option>)
                } else {
                  return (<option key={day.key} value={day.key}>{day.value}</option>)
                }
              })}
            </Input>
          </Col>
        </Row>
      </>
    } else {
      return <>
        <Row style={modalColor}>
          <Col lg="12">
            There were no schedule records found for <b>{schedule}</b>. Please contact your environment Admin if you believe this is a mistake. 
          </Col>
        </Row>
      </>
    }
    
  }

  render() {

    const bttnStyle = {
      borderRadius: "24px",
      border: "2px",
      padding: "5px 20px",
      textAlign: "center",
      display: "inline-block",
      margin: "4px 9px",
    }

    const modalColor = {
      backgroundColor:'rgba(27, 32, 99, 1)',
      color:'white'
    }


    return (
      <>
        {this.doesHaveScheduledInstances(this.hosts) && (
          <Button style={{maxWidth: '230px', 
          borderRadius: "24px", 
          padding: "2px 20px", 
          textAlign: "center",
          cursor: "pointer"}}  color="secondary" block onClick={this.toggle}>Scheduling</Button>
        )}
        
        <Modal size='lg' style={modalColor} isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader style={modalColor} toggle={this.toggle}>Schedules for {this.environment.name}</ModalHeader>
          <Form style={modalColor} onSubmit={this.handleSubmit}>
            <ModalBody style={modalColor}>
              {this.state.isLoading === true && (
                <Spinner> </Spinner>
              )}
              {this.state.isLoading === false && (
                
                <>
                  { Object.keys(this.hosts_by_schedule).map((schedule, i) => {
                    let scheduled_hosts = this.hosts_by_schedule[schedule];
                    return (
                      <>
                        <h5>{schedule}</h5>
                        <this.ScheduledInstances hosts={scheduled_hosts}/>
                        {schedule !== "Unscheduled" && (
                          <this.ScheduleFormElements 
                            schedule={schedule} 
                            available_schedules={this.state.schedules} 
                            beginTimeChange={this.handleBeginTimeChange} 
                            endTimeChange={this.handleEndTimeChange} 
                            startDayChange={this.handleStartDayChange} 
                            endDayChange={this.handleEndDayChange} 
                          />
                        )}
                        <br/>
                      </>
                    )
                  })}
                </>
              )}
            </ModalBody>
            <ModalFooter style={modalColor}>
              {this.state.isSubmitting && (
                <Spinner> </Spinner>
              )}
              {!this.state.isSubmitting && (
                <Button style={bttnStyle} color="success" onClick={this.handleSubmit}>Update Schedules</Button>
              )}
              <Button style={bttnStyle} color="danger" onClick={this.toggle}>Cancel</Button>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  }
}

export default ScheduleModals