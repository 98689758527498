import { useState } from 'react'
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Form,
  FormGroup,
  Label,
  Input
} from 'reactstrap'
import axios from "axios"
import Multiselect from 'multiselect-react-dropdown';
import env from '../../../lib/env';


const UserModals = ({ userName, userId, permissionLevel, permissionScope, allowedJurisdictions, allJurisdictions, auth, jurisdictionSetting }) => {
  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 20000,
    headers: auth.getAuthorizationHeaders()
  })

  const jurisdictions = allJurisdictions;
  const jurisdictionName = jurisdictionSetting;
  const [editModal, setEditModal] = useState(false)
  const [permission_level, setPermissionLevel] = useState(permissionLevel)
  const [permission_scope, setPermissionScope] = useState(permissionScope)
  const [allowed_jurisdictions, setAllowedJurisdictions] = useState(allowedJurisdictions)

  const toggleEditModal = () => {
    console.log(editModal)
    setEditModal(!editModal)
  }

  const onChange = (e) => {
    if (e.target.id === "permissionLevel") {
      setPermissionLevel(e.target.value)
    } else if (e.target.id === "permissionScope") {
      setPermissionScope(e.target.value)
    }
  }

  const onAddMultiSelect = (selectedList, addedItem) => {
    let temp_jurisdictions = allowed_jurisdictions
    temp_jurisdictions.push(addedItem.name)
    setAllowedJurisdictions(temp_jurisdictions)
  }

  const onRemoveMultiSelect = (selectedList, removedItem) => {
    console.log(selectedList)
    console.log(removedItem)
    let temp_jurisdictions = allowed_jurisdictions
    const index = temp_jurisdictions.indexOf(removedItem.name)
    temp_jurisdictions.splice(index, 1)
    setAllowedJurisdictions(temp_jurisdictions)
  }

  const deleteUser = (e) => {
    e.preventDefault()
    let confirmAction = window.confirm(`Are you sure you want to delete ${userName} from the DB?`)
    if (confirmAction) {
      axiosClient.delete(`/users/${userId}`)
      .then(resp => {
        console.log("Response: ", resp)
        toggleEditModal()
      })
      .catch(err => {
        console.error("API Error: ", err)
        alert('Something Went Wrong. Please Try Again Later.')
        toggleEditModal()
      })
      window.location.reload(false)
    } else {
      alert("Action canceled");
    }
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    let sendData = {
      permission_level: permission_level,
      permission_scope: permission_scope, 
      allowed_jurisdictions: allowed_jurisdictions
    }

    console.log(sendData)
    axiosClient.put(`/users/${userId}`, sendData)
    .then(resp => {
      console.log("Response: ", resp)
      alert("User Updated Successfully!")
      window.location.reload(false)
    })
    .catch(err => {
      console.error("API Error: ", err)
      alert('Something Went Wrong. Please Try Again Later.')
      window.location.reload(false)
    })
  }

  const parseAvailableJurisdictions = (available_jurisdictions) => {
    return available_jurisdictions.map(jur => {
      return {name: jur.name, id: jur.name}
    })
  }

  const parseSelectedJurisdictions = (jur_array) => {
    return jur_array.map(jur => {
      return {name: jur, id: jur}
    })
  }

  return (
    <>


        <>
          <Button color="primary" style={{maxWidth: '230px', 
                    borderRadius: "24px", 
                    padding: "2px 20px", 
                    textAlign: "center",
                    cursor: "pointer"}} onClick={() => toggleEditModal()}
            // disabled={auth.user.name === user.name}
            block
          >
            Edit
          </Button>
          
          <Modal isOpen={editModal} toggle={toggleEditModal}>
            <Form onSubmit={e => {handleSubmit(e)}} style={{backgroundColor:'rgba(34, 46, 107, 0.9)'}}>
              <ModalHeader toggle={toggleEditModal} style={{color:'white'}}>{userName}</ModalHeader>
              <ModalBody>
                
                  <FormGroup >
                    <Label for="permissionLevel"  style={{color:'white'}} >Permission Level</Label>
                    <Input
                      type="select"
                      value={permission_level}
                      id="permissionLevel"
                      onChange={onChange}
                    >
                      <option value="admin">Admin</option>
                      <option value="power">Power User</option>
                      <option value="operator">Operator</option>
                      <option value="read_only">Read Only</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="permissionScope" style={{color:'white'}}>Permission Scope</Label>
                    <Input
                      type="select"
                      value={permission_scope}
                      id="permissionScope"
                      onChange={onChange}
                    >
                      <option value="global">Global</option>
                      <option value="dev">Dev</option>
                      <option value="qa">QA</option>
                    </Input>
                  </FormGroup>
                  <FormGroup>
                    <Label for="permissionScope" style={{color:'white'}}>{jurisdictionName} Access:</Label>
                    <Multiselect
                      options={parseAvailableJurisdictions(jurisdictions)} // Options to display in the dropdown
                      selectedValues={parseSelectedJurisdictions(allowed_jurisdictions)} // Preselected value to persist in dropdown
                      onSelect={onAddMultiSelect} // Function will trigger on select event
                      onRemove={onRemoveMultiSelect} // Function will trigger on remove event
                      displayValue="name" // Property name to display in the dropdown options
                      style={{backgroundColor:'white'}}
                    />
                  </FormGroup>
                
              </ModalBody>
              <ModalFooter>
                <Button color="success" block style={{maxWidth: '230px',borderRadius: "24px",padding: "2px 20px",textAlign: "center",margin: 'auto',cursor: "pointer"}}>Submit</Button>
                <Button color="secondary" onClick={() => toggleEditModal()} block style={{maxWidth: '230px',borderRadius: "24px", padding: "2px 20px",textAlign: "center",margin: 'auto', cursor: "pointer"}}>Cancel</Button>
                <Button style={{ maxWidth: '230px', margin: 'auto', padding: "2px 20px", marginTop: "20px", backgroundColor: "transparent", color: '#ffc6c4', borderColor: '#ffc6c4',  borderRadius: "24px", cursor: "pointer",  }} onClick={deleteUser} block>Delete User</Button>
              </ModalFooter>
            </Form>
          </Modal>
          
        </>

    </>
    


  )
}

export default UserModals
