const domainName = window.location.hostname;

let environment = 'localhost'
if (domainName !== 'localhost') {
  environment = domainName.split('.')[1];
}

const environmentValues = {
  staging: {
    REACT_APP_SIGN_IN_URL: 'https://api.staging.cmp.ccoe.igt.com/users/saml/sign_in',
    REACT_APP_BASE_API_URL: 'https://api.staging.cmp.ccoe.igt.com',
    REACT_APP_AWS_ACCOUNT_NAME: 'Staging',
    REACT_APP_AWS_TITLE: 'Staging',
    REACT_APP_AWS_ENVIRONMENT: 'Stage',
    REACT_APP_AWS_ACCOUNT_NUMBER: 407387044487,
    REACT_APP_AWS_DEFAULT_CC: '4415'
  },
  cas: {
    REACT_APP_SIGN_IN_URL: 'https://api.cas.cmp.ccoe.igt.com/users/saml/sign_in',
    REACT_APP_BASE_API_URL: 'https://api.cas.cmp.ccoe.igt.com',
    REACT_APP_AWS_ACCOUNT_NAME: 'Casino Dev & Test',
    REACT_APP_AWS_TITLE: 'Casino',
    REACT_APP_AWS_ENVIRONMENT: 'Dev/Test',
    REACT_APP_AWS_ACCOUNT_NUMBER: 845023476577,
    REACT_APP_AWS_DEFAULT_CC: '100685'
  },
  vlt: {
    REACT_APP_SIGN_IN_URL: 'https://api.vlt.cmp.ccoe.igt.com/users/saml/sign_in',
    REACT_APP_BASE_API_URL: 'https://api.vlt.cmp.ccoe.igt.com',
    REACT_APP_AWS_ACCOUNT_NAME: 'VLT TEST',
    REACT_APP_AWS_TITLE: 'VLT',
    REACT_APP_AWS_ENVIRONMENT: 'Dev/Test',
    REACT_APP_AWS_ACCOUNT_NUMBER: 767867323362,
    REACT_APP_AWS_DEFAULT_CC: '105722'
  },
  localhost: {
    REACT_APP_SIGN_IN_URL: 'http://localhost:3000/users/saml/sign_in',
    REACT_APP_BASE_API_URL: 'http://localhost:3000',
    REACT_APP_AWS_ACCOUNT_NAME: 'LOCALHOST',
    REACT_APP_AWS_TITLE: 'LOCALHOST',
    REACT_APP_AWS_ENVIRONMENT: 'LOCALHOST',
    REACT_APP_AWS_ACCOUNT_NUMBER: 123456789,
    REACT_APP_AWS_DEFAULT_CC: '111111'
  }
}

const env = environmentValues[environment]

export default env;
