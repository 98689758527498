

const statusActions = {
  defineStatusStyle: function(status) {
    if (status === 'stopped') {
      return { color: 'red' }
    } else if (status === 'running') {
      return { color: 'green' }
    } else {
      return { color: 'grey' }
    }
  },
}

  


export default statusActions