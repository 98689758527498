import React from 'react'
import { Route, Redirect } from 'react-router-dom'
import { useAuth } from '../../../lib/useAuth'


const PrivateRoute = ({ props: passedProps, path, component: Component, ...rest }) => {
    // console.log("Private Route Path: ", path)
    // console.log("Private Route Props: ", passedProps)

    const auth = useAuth()

    return (
      <Route
        {...rest}
        path={path}
        render={(routerProps) => {
          if (auth.user) {
            return (
              <Component {...routerProps} {...passedProps} />
            )
          } else {
            return (
              <Redirect to="/" />
            )
          }
        }}
      />
    )
}

export default PrivateRoute