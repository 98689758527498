import React, { useState } from 'react'
import {
  Button,
  Col,
  Container,
  InputGroup,
  Input,
  Row,
  Table,
} from 'reactstrap'
import {
  useAuth
} from '../../../lib/useAuth'
import SimpleLoader from "./SimpleLoader"
import axios from 'axios'
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import env from '../../../lib/env'

const AmiCounter = () => {
  const auth = useAuth()

  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 20000,
    headers: auth.getAuthorizationHeaders()
  })

  const [formData, setFormData] = useState()
  const [tableData, setTableData] = useState()
  const [isLoading, setIsLoading] = useState(false)

  const onChange = (e) => {
    let currentFormData = {}
    currentFormData[e.target.id] = e.target.value
    setFormData(currentFormData)
  }

  const onSearch = () => {
    setIsLoading(true)
    axiosClient.get(`/housekeeper/list_multiple_amis`, {
      params: formData
    })
    .then(resp => {
      setIsLoading(false)
      setTableData(resp.data)
    })
  }

  const styleColor ={
    color: 'white'
  }

  const tableStyles = {
    color: 'white',
    backgroundColor:'rgba(27, 32, 99, 0.9)',
    borderColor:'rgb(0, 123, 255)',
    borderRadius: "5px",
  }

  return (
    <Container fluid>
      <Row>
        <Col lg='12'>
          <BreadcrumbGroup
            items={[
              { 
                text: "Home", 
                href: "/auth" 
              },
              { 
                text: "Administration", 
                href: "/administration"
              },
              {
                text: "Ami Counter"
              }
            ]}
            ariaLabel="Jurisdiction Environment Breadcrumbs"
          />
        </Col>
        <br />
        <br />
      </Row>
      {auth.user.permission_level !== "admin" && (
        <Col style={styleColor}>You are not authorize to view this page</Col>
      )}
      {auth.user.permission_level === "admin" && auth.user.permission_scope === "global" && (
        <>
        <Row>
          <Col sm='4'>
            <InputGroup>
              <Input name="count" id="maxCount" placeholder="Enter max amount of AMIs" onChange={onChange} />
              <Button color='primary' onClick={onSearch}> Search </Button>
            </InputGroup>
          </Col>
        </Row>
        <br />

        <Row>
          <Col lg={{ size: 6, offset: 3 }}>
            <h1 className="text-center" style={styleColor}>AMI Counter</h1>
          </Col>
        </Row>
        <Row>
          <Col lg='10' style={{margin:'auto'}}>
            {
              isLoading ?
                <SimpleLoader
                  type="ThreeDots"
                  isLoading={isLoading}
                />
                :
                <Table style={tableStyles}>
                  <thead>
                    <tr>
                      <th style={styleColor}>#</th>
                      <th style={styleColor}>Name</th>
                      <th style={styleColor}>Count</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableData && tableData.map((obj, i) => {
                        return (
                          <tr key={i}>
                            <td>{i+1}</td>
                            <td>{obj['name']}</td>
                            <td>{obj['count']}</td>
                          </tr>
                        )
                      })
                    }
                  </tbody>
                </Table>
            }

          </Col>
        </Row>
        </>
      )}
    </Container>
  )
}

export default AmiCounter
