import { useState, useContext, createContext } from "react";
import axios from 'axios'
import env from "./env";

const authContext = createContext()
const apiClient = axios.create({
  baseURL: env.REACT_APP_BASE_API_URL,
  timeout: 20000
})

export const ProvideAuth = ({ children }) => {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

const useProvideAuth = () => {
  const tempUser = JSON.parse(sessionStorage.getItem('user'))
  const tempCreds = JSON.parse(sessionStorage.getItem('authHeaders'))
  const [user, setUser] = useState(tempUser !== null ? tempUser : false)
  const [authHeaders, setAuthHeaders] = useState(tempCreds !== null ? tempCreds : false)

  const signin = () => {
    window.location.href = env.REACT_APP_SIGN_IN_URL
  }

  const signout = () => {
    setUser(false)
    setAuthHeaders(false)
    window.location.href = "/"
  }

  const setAuthorizationHeaders = (headers) => {
    sessionStorage.setItem('authHeaders', JSON.stringify(headers))
    setAuthHeaders(headers)
    apiClient.get('/auth/validate_token', { headers: headers })
      .then(response => {
        sessionStorage.setItem('user', JSON.stringify(response.data.data))
        setUser(response.data.data)
      })
      .catch(err => {
        console.error("API Error: ", err)
      })
  }

  const getAuthorizationHeaders = () => {
    return authHeaders
  }

  return {
    user,
    authHeaders,
    signin,
    signout,
    setAuthorizationHeaders,
    getAuthorizationHeaders
  }
}