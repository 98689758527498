import {
  useEffect,
  useState
} from 'react'
import {
  Row,
  Col,
  Form,
  Table,
  Input,
  Label,
  Button,
  Spinner, 
  Container
} from 'reactstrap'
import {
  useAuth
} from '../../../lib/useAuth'
import { IgtLoader } from '../../atoms'
import axios from 'axios'
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import env from '../../../lib/env'


const PortalSettings = () => {
  const auth = useAuth()
  const initialFormData = {
    tag_prefix: ""
  }
  const [formData, setFormData] = useState(initialFormData)
  const [tagPrefix, setTagPrefix] = useState("")
  const [scheduleTableName, setScheduleTableName] = useState("")
  const [customJurisdictionName, setCustomJurisdictionName] = useState("")
  const [customEnvironmentName, setCustomEnvironmentName] = useState("")
  const [allowBackup, setAllowBackup] = useState("")
  const [allowTerminate, setAllowTerminate] = useState("")
  const [restrictJurisdictions, setRestrictJurisdictions] = useState("")
  const [showRunningStoppedCost, setShowRunningStoppedCost] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const skipKeys = ['id', 'created_at', 'updated_at']

  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 20000,
    headers: auth.getAuthorizationHeaders()
  })

  useEffect(() => {
    axiosClient.get('/settings/1')
      .then(resp => {
        console.log(resp)
        let settings = resp.data
        let formDataObject = {}
        Object.keys(settings).forEach(key => {
          if (!skipKeys.includes(key)) {
            formDataObject[key] = settings[key]
          }
        })
        setTagPrefix(settings["tag_prefix"])
        setScheduleTableName(settings["schedule_table_name"])
        setCustomJurisdictionName(settings["jurisdiction"])
        setCustomEnvironmentName(settings["environment"])
        setAllowBackup(settings["allow_backup"])
        setAllowTerminate(settings["allow_terminate"])
        setRestrictJurisdictions(settings["restrict_jurisdictions"])
        setShowRunningStoppedCost(settings["show_running_stopped_cost"])
        console.log(formDataObject)
        setFormData(formDataObject)
        setIsLoading(false)
      })
      .catch(err => {
        console.error(err)
      })
    // eslint-disable-next-line 
  }, [])

  const onChange = (e) => {
    if (e.target.id === "tag_prefix") {
      setTagPrefix(e.target.value)
    } 
    if (e.target.id === "schedule_table_name") {
      setScheduleTableName(e.target.value)
    }
    if (e.target.id === "jurisdiction") {
      setCustomJurisdictionName(e.target.value)
    }
    if (e.target.id === "environment") {
      setCustomEnvironmentName(e.target.value)
    }
    let tempFormData = formData
    tempFormData[e.target.id] = e.target.value
    setFormData(tempFormData)
  }

  const onSwitchChange = (event, value) => {
    let key = event.target.id
    if (key === "allow_backup") {
      setAllowBackup(value);
    } else if (key === "allow_terminate") {
      setAllowTerminate(value)
    } else if (key === "restrict_jurisdictions") {
      setRestrictJurisdictions(value)
    } else if (key === "show_running_stopped_cost") {
      setShowRunningStoppedCost(value)
    }

    let tempFormData = formData
    tempFormData[key] = value
    setFormData(tempFormData)
  }

  const onSubmit = (e) => {
    console.log(formData);
    setIsSubmitting(true)
    axiosClient.put('/settings/1', formData)
    .then(resp => {
      console.log(resp)
      alert("Settings Updated Successfully!")
      setIsSubmitting(false)
    })
    .catch(err => {
      console.error(err)
      alert("Error Updating Settings: " + err)
      setIsSubmitting(false)
    })
  }

  const formItems = [
    {
      name: "Tag Prefix",
      id: "tag_prefix",
      type: "text",
      value: tagPrefix
    },
    {
      name: "Scheduler Table Name",
      id: "schedule_table_name",
      type: "text",
      value: scheduleTableName
    },
    {
      name: "Custom Jurisdiction Value",
      id: "jurisdiction",
      type: "text",
      value: customJurisdictionName
    },
    {
      name: "Custom Environment Value",
      id: "environment",
      type: "text",
      value: customEnvironmentName
    },
    {
      name: "Allow Backup Action",
      id: "allow_backup",
      type: "toggle",
      value: allowBackup
    },
    {
      name: "Allow Terminate Action",
      id: "allow_terminate",
      type: "toggle",
      value: allowTerminate
    },
    {
      name: "Restrict Jurisdiction per User",
      id: "restrict_jurisdictions",
      type: "toggle",
      value: restrictJurisdictions
    },
    {
      name: "Show Running and Stopped Cost on Environment",
      id: "show_running_stopped_cost",
      type: "toggle",
      value: showRunningStoppedCost
    }
  ]

  const txtColor = {
    color:'white',
  }

  return (
    <Container fluid>
      <Row>
        <Col lg='12'>
          
          <BreadcrumbGroup
            items={[
              { 
                text: "Home", 
                href: "/auth" 
              },
              { 
                text: "Administration", 
                href: "/administration"
              },
              {
                text: "Portal Settings"
              }
            ]}
            ariaLabel="Portal Settings Environment Breadcrumbs"
          />
        </Col>
        <br />
        <br />
      </Row>
      <>
        {isLoading && (
          <IgtLoader />
        )}
        {!isLoading &&  (
          <Row>
            <Col lg="12">
              <Form>
                <Table>
                  <thead>
                    <tr>
                      <th style={txtColor}>Setting</th>
                      <th style={txtColor}>Value</th>
                    </tr>
                  </thead>
                  <tbody>
                    {formData && formItems && (
                        formItems.map((obj, i) => {
                          console.log(obj.name, obj.type)
                          if (obj.type === "text") {
                            return (
                              <tr key={i} style={txtColor}>
                                <td >
                                  <Label for={obj.id} >{obj.name}</Label>
                                </td>
                                <td>
                                  <Input id={obj.id} name={obj.id} type='text' value={obj.value} onChange={(e) => onChange(e)}/>
                                </td>
                              </tr>
                            )
                          } else if (obj.type === "toggle") {
                            return (
                              <tr key={i} style={txtColor}>
                                <td>
                                  <Label for={obj.id}>{obj.name}</Label>
                                </td>
                                <td>
                                  <input type="checkbox" id={obj.id} name={obj.id} checked={obj.value} onChange={(e) => {onSwitchChange(e, !obj.value)}} />

                                </td>
                              </tr>
                            )
                          } else {
                            return false;
                          }
                        })
                    )}
                  </tbody>
                </Table>
                {isSubmitting && (
                  <Spinner style={{ float: 'right' }}> </Spinner>
                )}
                {!isSubmitting && (
                  <Button style={{ 
                    float: 'right', 
                    borderRadius: "24px",
                    padding: "2px 20px",
                    textAlign: "center", 
                  }}color="success" onClick={onSubmit}>Update Settings</Button>
                )}
              </Form>
              <br/>
              <p style={{ color: 'white', paddingLeft: '10px', fontSize: '.9em'}}>More settings will be added on this page as they are defined.</p>
            </Col>
          </Row>
        )}
      </>
    </Container>
  )
}

export default PortalSettings