import {
  useState
} from 'react'
import {
  Row,
  Col,
  Button,
  CardBody,
  CardText,
  CardTitle,
  Card
} from 'reactstrap'
import {
  useModelList
} from '../../customHooks'
import {
  IgtLoader
} from '../../atoms'
import axios from 'axios'
import {
  useAuth
} from '../../../lib/useAuth'
import IssueModals from '../../molecules/issuemodals/IssueModals'
import env from '../../../lib/env'


const AuthHome = () => {
  const auth = useAuth()
  const [isLoading, setIsLoading] = useState(null)
  const [modelList] = useModelList('jurisdictions', setIsLoading, null);
  modelList?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
  console.log(isLoading);
  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 20000,
    headers: auth.getAuthorizationHeaders()
  })

 
  const syncAll = () => {
    
    axiosClient.post(`/sync/all`)
      .then(resp => {
        console.log('API Response: ', resp)
        alert('Resource Sync Queued')
        window.location.reload(false)
      })
      .catch(error => {
        console.log(error.response)
      })
  }

  const getRunningInstancesForEachJurisdiction = (environments) => {
    let runningCount = 0;
    let totalCount = 0;
    environments.forEach(environment => {
      console.log(environment);
      environment.hosts.forEach(host => {
        if (host.instance !== null) {
          totalCount += 1;
          if (host.instance.status === "running") {
            runningCount += 1
          }
        } else {
          if (host.nodes.length > 0) {
            host.nodes.forEach(node => {
              totalCount += 1;
              if (node.status === "running") {
                runningCount += 1
              }
            })
          }
        }
      });
    })

    return `${runningCount}/${totalCount}`
  }

  const bttnStyle = {
    borderRadius: "24px",
    border: "2px",
    padding: "5px 20px",
    textAlign: "center",
    display: "inline-block",
    margin: "4px 0px",
    cursor: "pointer",
  };

  const syncBttn = {
    borderRadius: "24px",
    padding: "2px 20px",
    textAlign: "center",
    textDecoration: "none",
    display: "inline-block",
    cursor: "pointer",
    width: '150px',
  };

  return (
    <>
    
      {
        isLoading &&
        <IgtLoader />
      }
      {
        !isLoading && modelList !== null && 
        <>
                

        <Col>
          {
            auth.user.permission_level === "admin" && auth.user.permission_scope === "global" &&

            <Col lg="1">
              <br/>
              <Button 
                    id="someid" color='primary' block onClick={syncAll} style={syncBttn}>&#8635; Sync
              </Button>
              <br/>
            </Col>
          }
        </Col>
        <br />

                <Row>
                  {
                    modelList.map((model, i) => {
                      
                      return (
                        
                            <Col lg='4' style={{marginTop: '10px', marginBottom: '10px'}}  key={i}>
                                    
                                    <Card style={{ width:'95%', color:'white', paddingTop:'10px', paddingBottom:'10px', 
                                        backgroundColor:'rgba(27, 32, 99, 1)',
                                        borderColor:'rgb(0, 123, 255)'}}>
                                      <CardBody>
                                        <CardTitle tag="h4" style={{fontSize: '1.4em'}}><strong><a style={{ color: '#007BFF'}} href={`/environments/${model.id}`}>{model.name}</a></strong></CardTitle>
                                        <CardText tag={"h5"}>
                                          {getRunningInstancesForEachJurisdiction(model.environments)} Instances Running
                                        </CardText>
                                        <Button color='primary' style={bttnStyle} href={`/environments/${model.id}`}>{model.name}</Button>
                                      </CardBody>
                                    </Card>
                            </Col>  
                        )
                      })
                    }
                </Row>
            
                <Row>
                  <Col lg="2">
                    <br/>
                    <a href="https://gtechcorp.sharepoint.com/:f:/r/sites/CloudCenterofExcellence/Shared%20Documents/General/VLT%20Dashboard?csf=1&web=1&e=KRXfuR" target="_blank" rel="noreferrer">
                      <Button style={bttnStyle} color="primary" block>User Guides</Button></a>
                    <br/>
                  </Col>
                  <Col lg="2">
                    <br/>
                    <IssueModals axiosClient={axiosClient} auth={auth}/>
                    <br/>
                  </Col>
                </Row>

             
        </>
      }
  
        <br/>
       
    </>
    
  )
}

export default AuthHome