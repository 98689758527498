import React from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import VolumeInfo from '../volumeinfo/VolumeInfo'
import ImageInfo from '../imageinfo/ImageInfo'
import SnapshotInfo from '../snapshotinfo/SnapshotInfo'

class NodeModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
      volumes: props.volumes,
      node: props.node,
      host: props.host
    }
    this.toggle = this.toggle.bind(this);
  }

  toggle () {
    this.setState({
      modal: !this.state.modal
    })
  }

  render () {

    const modalColor = {
      backgroundColor:'rgba(27, 32, 99, 1)',
      color:'white',
      maxWidth: '900px', 
      width: '100%'
    }

    const bttnStyle = {
      borderRadius: "24px",
      border: "2px",
      padding: "5px 20px",
      textAlign: "center",
      display: "inline-block",
      margin: "4px 9px",
    }

    return (
      <>
        <Button 
          color="secondary" 
          onClick={this.toggle} 
          style={{
            maxWidth: '230px', 
            borderRadius: "24px", 
            padding: "3px 20px", 
            textAlign: "center",
            alignItems: 'center',
            margin: "auto",
            cursor: "pointer" 
          }}
        >
          View Data
        </Button>
        <Modal style={modalColor} size="xl" isOpen={this.state.modal} toggle={this.toggle} >
          <ModalHeader style={modalColor} toggle={this.toggle}>{this.state.node.identifier + " - " + this.state.host.name}</ModalHeader>
            <ModalBody style={modalColor}>
              <p>Listed below are resources associated with this instance.</p>
              <VolumeInfo volumes={this.state.volumes}/>
              {/* We don't have Images or Snapshots in the Casino Deployment, so for now just sending empty arrays */}
              <ImageInfo amis={[]} comments={[]} />
              <SnapshotInfo snapshots={[]}/>
            </ModalBody>
            <ModalFooter style={modalColor}>
              <Button style={bttnStyle} color="danger" onClick={this.toggle}>Close</Button>
            </ModalFooter>
        </Modal>
      </>
    )
  }

}

export default NodeModal