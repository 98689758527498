import React, { useEffect, useState } from 'react'
import {
  Col,
  Container,
  Row,
  Table
} from 'reactstrap'
import {
  useAuth
} from '../../../lib/useAuth'
import SimpleLoader from "./SimpleLoader"
import { Chart } from "react-google-charts";
import axios from 'axios'
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import env from '../../../lib/env';

const PortalStatistics = () => {
  const auth = useAuth()
  console.log(auth.user)

  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 20000,
    headers: auth.getAuthorizationHeaders()
  })

  const [resourceData, setResourceData] = useState()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    axiosClient.get(`/housekeeper/statistics`)
      .then(resp => {
        console.log(resp.data)
        setResourceData(resp.data)
        setIsLoading(false)
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const formatInstancePieChartData = (resourceData) => {
    return [
      ["Instance", "Count"],
      ["Active Instances", resourceData.active_instances],
      ["Terminated Instances", resourceData.inactive_instances],
    ]
  }

  const formatInstanceVsAmiPieChartData = (resourceData) => {
    return [
      ["Instance/AMI", "Count"],
      ["Instances", resourceData.active_instances],
      ["AMIs", resourceData.amis],
    ]
  }

  const formatVolumeVsSnapshotPieChartData = (resourceData) => {
    return [
      ["Volumes/Snapshots", "Count"],
      ["Volumes", resourceData.volumes],
      ["Snapshots", resourceData.snapshots],
    ]
  }

  const tableStyles = {
    color: 'white',
    backgroundColor:'rgba(27, 32, 99, 0.9)',
    borderColor:'rgb(0, 123, 255)',
    borderRadius: "5px",
  }


  return (
    <Container fluid>
      <Row>
        <Col lg='12'>
          
          <BreadcrumbGroup
            items={[
              { 
                text: "Home", 
                href: "/auth" 
              },
              { 
                text: "Administration", 
                href: "/administration"
              },
              {
                text: "Portal Settings"
              }
            ]}
            ariaLabel="Portal Settings Environment Breadcrumbs"
          />
        </Col>
        <br />
        <br />
      </Row>
      {auth.user.permission_level !== "admin" && (
        <Col>You are not authorize to view this page</Col>
      )}
      {auth.user.permission_level === "admin" && auth.user.permission_scope === "global" && (
        <>
        <Row>
        </Row>
        <Row>
          <Col style={{color: 'white'}} lg={{ size: 6, offset: 3 }}>
            <h1 className="text-center">Portal Stats</h1>
          </Col>
        </Row>

        <Row>
          <>
            {
              isLoading ?
              <Col lg='12'>
                <SimpleLoader
                  type="Oval"
                  isLoading={isLoading}
                />
              </Col>
                :
                <>
                  <Col lg='6'>
                    <Table style={tableStyles}>
                      <thead>
                        <th>Resource Type</th>
                        <th>Count</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Jurisdictions</td>
                          <td>{resourceData.jurisdictions}</td>
                        </tr>
                        <tr>
                          <td>Environments</td>
                          <td>{resourceData.environments}</td>
                        </tr>
                        <tr>
                          <td>Available Instances</td>
                          <td>{resourceData.active_instances}</td>
                        </tr>
                        <tr>
                          <td>Terminated Instances</td>
                          <td>{resourceData.inactive_instances}</td>
                        </tr>
                        <tr>
                          <td>AMIs</td>
                          <td>{resourceData.amis}</td>
                        </tr>
                        <tr>
                          <td>Volumes</td>
                          <td>{resourceData.volumes}</td>
                        </tr>
                        <tr>
                          <td>Snapshots</td>
                          <td>{resourceData.snapshots}</td>
                        </tr>
                      </tbody>
                    </Table>
                  </Col>
                  <Col lg='6'>
                    <Chart 
                      chartType="PieChart"
                      data={formatInstancePieChartData(resourceData)}
                      options={{title: "Active vs Terminated Instances", pieSliceText: "value"}}
                      width={"100%"}
                      height={"400px"}
                    />
                  </Col>
                  <Col lg='6'>
                    <Chart 
                      chartType="PieChart"
                      data={formatInstanceVsAmiPieChartData(resourceData)}
                      options={{title: "Instances to AMIs", pieSliceText: "value"}}
                      width={"100%"}
                      height={"400px"}
                    />
                  </Col>
                  <Col lg='6'>
                    <Chart 
                      chartType="PieChart"
                      data={formatVolumeVsSnapshotPieChartData(resourceData)}
                      options={{title: "Volumes to Snapshots", pieSliceText: "value"}}
                      width={"100%"}
                      height={"400px"}
                    />
                  </Col>
                </>
            }
          </>
        </Row>
        </>
      )}
    </Container>
  )
}

export default PortalStatistics
