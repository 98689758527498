import React from 'react'
import statusActions from '../../atoms/statusActions/StatusActions'
import {
  Spinner
} from 'reactstrap'
import axios from 'axios'
import env from '../../../lib/env'


class InstanceStatus extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      instance: props.instance,
      status: props.instance.status,
      isLoading: true,
      type: props.type
    }
    this.auth = this.props.auth;
    this.axiosClient = this.props.axiosClient;
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ status: nextProps.instance.status });
  }

  componentWillMount() {
    if (this.state.instance.identifier.includes("ami-")) {
      this.setState({isLoading: false})
    } else {
      let id = this.state.instance.id
      const axiosClient = axios.create({
        baseURL: env.REACT_APP_BASE_API_URL,
        timeout: 200000,
        headers: this.auth.getAuthorizationHeaders(),
      });
      const url = this.state.type === "EC2" ? `/instances/${id}/status` : `/nodes/${id}/status`
      axiosClient.get(url)
        .then(resp => {
          let new_status = resp.data.status
          this.setState({
            status: new_status,
            isLoading: false
          })
        })
        .catch(e => {
          this.setState({ isLoading: false })
          console.error(e)
        })
    }
    
  }

  render () {
    const statusStyle = statusActions.defineStatusStyle(this.state.status)
    return (
      <>
        {this.state.isLoading === false && (
          <td style={statusStyle}>{this.state.status}</td>
        )}
        {this.state.isLoading === true && (
          <td><Spinner> </Spinner></td>
        )}
      </>
      
    )
  }

}

export default InstanceStatus


