import {
  useState
} from 'react'
import {
  Row,
  Col,
  Table,
  Button,
  Spinner,
} from 'reactstrap'
import {
  useModelList,
} from '../../customHooks'
import {
  IgtLoader
} from '../../atoms'
import {
  useRouteMatch
} from 'react-router'
import {
  useAuth
} from '../../../lib/useAuth'
import axios from 'axios'
import HostModals from '../../molecules/hostmodals/HostModals'
import InstanceModals from '../../molecules/instancemodals/InstanceModals.jsx'
import ScheduleModals from '../../molecules/schedulemodals/ScheduleModals'
import InstanceStatus from '../../molecules/instancestatus/InstanceStatus'
import NodeList from '../../molecules/nodelist/NodeList'
import '../styles/styles.css'
import BreadcrumbGroup from "@cloudscape-design/components/breadcrumb-group";
import env from '../../../lib/env'

const Host = () => {
  const auth = useAuth()
  const match = useRouteMatch()
  const params = { user: auth.user.uid }
  const [isLoading, setIsLoading] = useState(true)
  const [modelList] = useModelList('hosts', setIsLoading, null, `environment_id=${match.params.environmentId}`, true)
  modelList?.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

  const [environment] = useModelList('environments', setIsLoading, match.params.environmentId, null)
  const [jurisdiction] = useModelList('jurisdictions', setIsLoading, match.params.jurisdictionId, null)
  const [settings] = useModelList('settings', setIsLoading, 1)
  const [isStartAllLoading, setIsStartAllLoading] = useState(false)
  const [isStopAllLoading, setIsStopAllLoading] = useState(false)
  // const [jurisdiction] = environment.jurisdiction
  console.log('Models: ', modelList)
  console.log('Environment: ', environment)
  console.log('Jurisdiction: ', jurisdiction)
  console.log(`Settings: `, settings)

  const computeCost = (status, instance, volumes) => {
    const instanceCost = instance.cost
    let volumeCost = 0
    volumes.forEach(volume => {volumeCost += parseFloat(volume.cost)})
    let number = parseFloat(volumeCost)
    if (status === "running") {
      number += parseFloat(instanceCost)
    }
    return number.toFixed(2)
  }

  //might need to update this if we end up having volumes that are assigned to each node
  const computeEksCost = (nodes, volumes, cluster) => {
    let nodeCost = 0;
    let volumeCost = 0;
    let clusterCost = parseFloat(cluster.cost)
    nodes.forEach(node => {nodeCost += parseFloat(node.cost)});
    volumes.forEach(volume => {volumeCost += parseFloat(volume.cost)});
    let number = parseFloat(nodeCost + volumeCost + clusterCost);
    return number.toFixed(2)
  }

  const axiosClient = axios.create({
    baseURL: env.REACT_APP_BASE_API_URL,
    timeout: 200000,
    headers: auth.getAuthorizationHeaders()
  })

  const startEnvironment = () => {
    setIsStartAllLoading(true)
    axiosClient.post(`/environments/${environment.id}/start`, params)
      .then(resp => {
        setIsStartAllLoading(false)
        console.log('API Response: ', resp)
        let alertMessage = 'Environment Start Queued!'
        alertMessage += getInstanceDelays()
        alert(alertMessage)
        window.location.reload(false)
      })
      .catch(err => {
        setIsStartAllLoading(false)
        console.error("API Error: ", err)
        alert("We can't start all of these instances, it is likely because some instances are not in the 'stopped' state.")
        window.location.reload(false)
      })
  }

  const getInstanceDelays = () => {
    let delayList = ""
    modelList.forEach(model => {
      if (model.instance.delay_start > 0) {
        delayList += `\n - ${model.name}: ${model.instance.delay_start} seconds`
      }
    })
    if (delayList.length > 0) {
      return "\n\nThe following instances are configured for a delayed start and will show as stopped for the specified amount of time: " + delayList
    } else {
      return ""
    }
  }

  const stopEnvironment = () => {
    setIsStopAllLoading(true)
    axiosClient.post(`/environments/${environment.id}/stop`, params)
      .then(resp => {
        setIsStopAllLoading(false)
        console.log('API Response: ', resp)
        alert('Environment Stop Queued!')
        window.location.reload(false)
      })
      .catch(err => {
        setIsStopAllLoading(false)
        console.error("API Error: ", err)
        alert("We can't stop all of these instances, it is likely because some instances are not in the 'running' state.")
        window.location.reload(false)
      })
  }

  const syncResources = () => {
    let environmentId = environment !== null ? environment.id : 'undef'
    
    axiosClient.post(`/sync/environment/${environmentId}`)
      .then(resp => {
        console.log('API Response: ', resp)
        alert('Resource Sync Queued')
        window.location.reload(false)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  const resetResources = () => {
    let environmentId = environment !== null ? environment.id : 'undef'
    
    axiosClient.post(`/reset/environment/${environmentId}`)
      .then(resp => {
        console.log('API Response: ', resp)
        alert('Environment Reset Queued')
        window.location.reload(false)
      })
      .catch(error => {
        console.log(error)
        console.log(error.response)
      })
  }

  const modelListHasEc2 = (modelList) => {
    let has_ec2 = false
    modelList.forEach(model => {
      if (model.host_type === "EC2" || model.host_type === null) {
        has_ec2 = true
        return has_ec2
      }
    })
    return has_ec2
  }

  const modelListHasEks = (modelList) => {
    let has_eks = false
    modelList.forEach(model => {
      if (model.host_type === "EKS" && model.cluster !== null) {
        has_eks = true
        return has_eks
      }
    })
    return has_eks
  }

  const getStatusColor = (status) => {
    if (status === "running") {
      return "green"
    } else if (status === "stopped") {
      return "red"
    } else {
      return "grey"
    }
  }

  const scaleCluster = (cluster) => {
    console.log(cluster)
    const uri = cluster.status === 'running' ? `/clusters/${cluster.id}/down` : `/clusters/${cluster.id}/up`
    const params = {
      name: cluster.name, 
      stack: cluster.stack_name
    }
    axiosClient.post(uri, params)
      .then(resp => {
        console.log(resp)
        alert('Cluster Scaling Queued! Please allow 5-10 minutes for all resources to be created/destroyed.')
        window.location.reload(false)
      }) 
      .catch(err => {
        console.error(err)
        alert('There was an issue with your request. Please perform an Environment Sync and try again later.')
        window.location.reload(false)
      })
  }

  const scalingOptions = (cluster) => {
    const buttonStyles = {
      maxWidth: '230px', 
      borderRadius: "24px", 
      padding: "2px 20px", 
      textAlign: "center",
      marginBottom: '50px',
      cursor: "pointer"
    }
    if (cluster.status === "running") {
      return <Button style={buttonStyles} color="danger" onClick={() => scaleCluster(cluster)} >Scale Down</Button>
    } else if (cluster.status === "stopped") {
      return <Button style={buttonStyles} color="success" onClick={() => scaleCluster(cluster)} >Scale Up</Button>
    } else if (cluster.status === "stopping") {
      return <Button style={buttonStyles} color="success" onClick={() => scaleCluster(cluster)} disabled>Scale Up</Button>
    } else if (cluster.status === "pending") {
      return <Button style={buttonStyles} color="danger" onClick={() => scaleCluster(cluster)} disabled>Scale Down</Button>
    } else {
      return <Button style={buttonStyles} color="secondary">No Actions</Button>
    }
  }

  const pageStyles = {
    color: 'white'
  }

  const tableStyles = {
    color: 'white',
    backgroundColor:'rgba(27, 32, 99, 0.9)',
    borderColor:'rgb(0, 123, 255)',
    borderRadius: "5px",
  }

  const startStopStyles = {
    color: 'white',
    marginLeft: '3%'
  }
  
  const headerTextColor = {
    color: 'white'
  }
  const column= {
    margin: "auto"
  }

  const resourceTitleStyles = {
    marginLeft: '8.5%', 
    marginBottom: '20px', 
    fontSize: '1.3em'
  }

  const eksClusterStyles = {
    marginLeft: '8.5%', 
    marginBottom: '5px', 
  }

  return (
    <>
      <br/>
      
      {
        (isLoading || modelList === null || environment === null || settings === null) &&
        <IgtLoader/>
      }
      {
        !isLoading && modelList !== null && environment !== null && settings !== null && 
        <>
          <Row >
            <Col >
              <br/>
              <BreadcrumbGroup
                items={[
                  { 
                    text: "Home", 
                    href: "/auth" 
                  },
                  { 
                    text: (jurisdiction !== null ? jurisdiction.name : '') + ` ${settings.jurisdiction}`, 
                    href: `/environments/${jurisdiction !== null ? jurisdiction.id : ''}` 
                  },
                  {
                    text: (environment !== null ? environment.name : '') + ` ${settings.environment}`
                  }
                ]}
                ariaLabel="Jurisdiction Environment Breadcrumbs"
              />
            </Col>   
          </Row>
          <Row style={headerTextColor}>
            <Col lg="8" style={{display: 'flex', alignItems: 'center', marginBottom: '25px'}}>
              <h3 style={{marginLeft: '13%'}}>{jurisdiction !== null ? jurisdiction.name : ''} {settings.jurisdiction}  -  {environment !== null ? environment.name : ''} {settings.environment}</h3>
            </Col>
            <Col lg="4" style={{ display: 'flex', flexDirection: 'row', justifyContent: 'right', marginBottom: '25px' }}>
              <Button style={{
                  maxWidth: '200px', 
                  borderRadius: "24px", 
                  padding: "3px 5px", 
                  textAlign: "center",
                  alignItems: 'center',
                  margin: "auto",
                  cursor: "pointer" 
                }} 
                color="primary" 
                block 
                onClick={syncResources}
                >
                  &#8635; Sync Environment
              </Button>
              {auth.user.permission_level === "admin" && (
                <Button style={{
                    maxWidth: '200px', 
                    borderRadius: "24px", 
                    padding: "3px 5px", 
                    textAlign: "center",
                    alignItems: 'center',
                    margin: "auto",
                    cursor: "pointer" 
                  }} 
                  color="danger" 
                  block 
                  onClick={resetResources}
                >
                  &#10005; Reset Environment
                </Button>
              )}
              
            </Col>
          </Row>
          { modelListHasEc2(modelList) && (
            <>
              <Row style={headerTextColor}>
                <Col lg="4" style={resourceTitleStyles}>
                  <h6 style={{fontSize: '1em'}}>EC2 Resources</h6>
                </Col>
              </Row>
              <Row>
                <Col style={column}  lg="10">
                  <Table style={tableStyles} hover>
                    <thead>
                      <tr>
                        <th>Hostname</th>
                        <th>Type</th>
                        <th>Instance ID</th>
                        <th>Monthly Cost*</th>
                        <th>Status</th>
                        <th>IP Address</th>
                        <th>Owner</th>
                        <th>Cost Center</th>
                        <th>Data</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        modelList.map((model, i) => {
                          if (model.instance !== null) {
                            const instance = model.instance
                            const volumes = model.volumes
                            const status = model.instance.status
                            const cost = computeCost(status, instance, volumes)
                            return (
                              <tr key={i}>
                                <td>{model.name}</td>
                                <td>{model.host_type === "EC2" ? model.host_type : "EC2"}</td>
                                <td>{instance.identifier}</td>
                                <td>~${cost}</td>
                                <InstanceStatus instance={instance} auth={auth} axiosClient={axiosClient} type={model.host_type}/>
                                <td>{model.ip_address}</td>
                                <td>{environment.owner_tag}</td>
                                <td>{environment.cost_center_tag}</td>
                                <td>
                                  <InstanceModals model={model} auth={auth} axiosClient={axiosClient}/>
                                </td>
                                <td>
                                  <HostModals model={model} auth={auth} settings={settings} axiosClient={axiosClient}/>
                                </td>
                              </tr>
                            )
                          } else {
                            return false;
                          }
                        })
                      }
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row lg="10" style={startStopStyles}>
                <Col lg="3">
                  { isStartAllLoading && (
                    <Spinner> </Spinner>
                  )}
                  { !isStartAllLoading && (
                    <Button color="success" 
                    style={{maxWidth: '200px', borderRadius: "24px", padding: "2px 20px", textAlign: "center",marginBottom: '50px',margin: "auto",cursor: "pointer"}} block onClick={startEnvironment}> Start All </Button>
                  )}
                </Col>
                <Col lg="3">
                  { isStopAllLoading && (
                    <Spinner> </Spinner>
                  )}
                  { !isStopAllLoading && (
                    <Button color="danger" 
                    style={{maxWidth: '200px', borderRadius: "24px", padding: "2px 20px", textAlign: "center", marginBottom: '50px', marginLeft: "10px", cursor: "pointer"}} 
                    block onClick={stopEnvironment}>Stop All</Button>
                  )}
                </Col>
                <Col lg="3">
                  <ScheduleModals models={modelList} auth={auth} axiosClient={axiosClient} environment={environment}/>
                </Col>
              </Row>
            </>
          )}
          { modelListHasEks(modelList) && (
            <>
              <br/>
              <Row style={pageStyles}>
              <Col lg="4" style={resourceTitleStyles}>
                  <h6 style={{fontSize: '1em'}}>EKS Resources</h6>
                </Col>
              </Row>
              
              { modelList.map((model, i) => {
                if (model.cluster !== null) {
                  const nodes = model.nodes
                  const volumes = model.volumes
                  const cluster = model.cluster
                  return (
                  <>
                    <Row style={pageStyles}>
                      <Col style={eksClusterStyles} lg="4">
                        <h6><b>Cluster</b></h6>
                      </Col>
                      <Col style={column} lg="10">
                        <Table style={tableStyles} hover>
                          <thead>
                            <th>Name</th>
                            <th>Type</th>
                            <th>Active Node Count</th>
                            <th>Cluster Stack Name</th>
                            <th>Monthly Cost*</th>
                            <th>Status</th>
                            <th>Total Cluster Cost (Nodes & Cluster)</th>
                            <th>Actions</th>
                          </thead>
                          <tbody>
                            <tr>
                              <td>{cluster.name}</td>
                              <td>EKS Cluster</td>
                              <td>{nodes.length} / {cluster.max_nodes}</td>
                              <td>{cluster.stack_name}</td>
                              <td>~${cluster.cost}</td>
                              <td><span style={{ color: getStatusColor(cluster.status) }}>{cluster.status}</span></td>
                              <td><b>~${computeEksCost(nodes, volumes, cluster)}</b></td>
                              <td>{scalingOptions(cluster)}</td>
                            </tr>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                    <Row style={pageStyles}>
                      <Col lg="4" style={eksClusterStyles}>
                        <h6 ><b>{model.name} Cluster Nodes</b></h6>
                      </Col>
                      <Col style={column} lg="10">
                        <Table style={tableStyles} hover>
                          <thead>
                            <tr>
                              <th>Node Name</th>
                              <th>Type</th>
                              <th>Instance ID</th>
                              <th>Monthly Cost*</th>
                              <th>Status</th>
                              <th>IP Address</th>
                              <th>Owner</th>
                              <th>Cost Center</th>
                              <th>Data</th>
                            </tr>
                          </thead>
                          <tbody>
                            <>
                              <NodeList nodes={nodes} host={model} volumes={volumes} environment={environment} axiosClient={axiosClient}/>                        
                            </>
                          </tbody>
                        </Table>
                      </Col>
                    </Row>
                  </>
                  )
                } else {
                  return false
                }
              })}
            </>
          )}
          <Row >
            <Col lg="10">
              <br/>
              <i className="italics">*Instance Monthly Cost is an estimate based off of the AWS Pricing API, for exact cost information view the <a href="https://us-east-1.console.aws.amazon.com/billing/home?region=ca-central-1#/">AWS Billing Dashboard</a> in the console. </i>
            </Col>
          </Row>
        </>
      }
    </>
  )
}

export default Host
