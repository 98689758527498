import React from 'react'
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const SimpleLoader = ({type, isLoading}) => {
  return (
    <>
      <center><h4 style={{color: 'white'}}>Querying Data from AWS, Please wait...</h4></center>
      <Loader
        type={type}
        color="#00BFFF"
        height={300}
        width={300}
        visible={isLoading}
        style={{
          position: 'absolute', left: '50%', top: '15%',
          transform: 'translate(-50%, 15%)',
        }}
      />
    </>

  )
}

export default SimpleLoader
